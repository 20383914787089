import { Modal } from "antd";
import ExitScan from "../../containers/face_recognition/components/exit_scan";
import FailScan from "../../containers/face_recognition/components/fail_scan";
import SuccessScan from "../../containers/face_recognition/components/success_scan";
import SuccessTicketUserDetail from "../../containers/face_recognition/components/success_ticket_user_detail";

// calculate responsive width and height
const { innerWidth, innerHeight } = window;
const calculated_width = (innerWidth - (innerWidth * 0.3));
const is_full_screen = innerHeight > innerWidth;
const responsive_width = is_full_screen ? innerWidth : calculated_width;
const responsive_height = (innerHeight);

const maskStyle = {
    position : 'absolute',
    marginRight : 'auto',
    marginLeft : 'auto',
    left : 0,
    right : 0,
    textAlign : 'center',
    width : responsive_width,
    height : responsive_height,
    top : 64,
}

async function speech(text, voice){
    if(text){
        if ( 'speechSynthesis' in window ){
            speechSynthesis.cancel(); // removes anything 'stuck'
            speechSynthesis.getVoices();
        }
        // const voice = getVoice();
        // localStorage
        let synth = speechSynthesis;
        let id;
        let utternance = new SpeechSynthesisUtterance(text);
        id = setInterval(() => {
            if(voice){
                // utternance.voice = voice;
                utternance.voice = voice;
            }
            speechSynthesis.speak(utternance); // speak the speech/utternance
            clearInterval(id);
        }, 10);
       
        // synth.cancel();
    }
}

async function modalHandler(result, entrance, face_image){
    let speech_text = "";
    if(result?.result){
        // const entrance_records = result?.ticket_user?.entrance_transactions;
        const is_checkout = result?.ticket_user?.is_checkout;
        if(is_checkout){
            speech_text = entrance?.exit_message;
            exitEntranceModal(result, entrance);
        }else{
            //speech sound
            speech_text = entrance?.display_message;
            successEntranceModal(result, entrance, face_image);
        }
    }else{
        speech_text = "Sorry you are not recognised.";
        failEntranceModal();
    }
    const default_voice = localStorage?.getItem("default_voice");
    const voice = speechSynthesis?.getVoices()[default_voice];
    speech(speech_text, voice);
}

async function successEntranceModal(result, entrance, face_image){
    // navigator.clipboard.writeText(result?.ticket_user?.ticket_user_no);
    const color = (result?.ticket_user?.ticket_user_type?.color);
    const { type, pass, face_image : result_image, account, ticket_user, } = result ?? {};
    Modal.success({
        icon : null,
        // transitionName : 'none',
        maskTransitionName : 'none',
        maskStyle : {
            ...maskStyle, 
            // background : '#38c172'
            background : color ?? "#38c172",
        },
        className : 'scan-success-modal',
        content : (
            <>
                <SuccessScan type={type} pass={pass} face_image={result_image} account={account} ticket_user={ticket_user} entrance={entrance} />
            </>
        ),
        footer : null,
    });
}

async function failEntranceModal(){
    Modal.error({
        // transitionName : 'none',
        maskTransitionName : 'none',
        icon : null,
        maskStyle : {
            ...maskStyle,
            background : '#e3342f'
        },
        className : 'scan-success-modal',
        content : (
            <>
                <FailScan />
            </>
        ),
        footer : null
    });
}

async function exitEntranceModal(result, entrance){
    Modal.success({
        icon : null,
        // transitionName : 'none',
        maskTransitionName : 'none',
        maskStyle : {
            ...maskStyle, 
            background : '#3490dc'
        },
        className : 'scan-success-modal',
        content : (
            <>
                <ExitScan entrance={entrance} result={result} />
            </>
        ),
        footer : null,
    });
}


async function ticketUserModal(result, face_image){
    const color = (result?.ticket_user?.ticket_user_type?.color);
    if(result?.ticket_user){
        Modal.success({
            icon : null,
            // transitionName : 'none',
            maskTransitionName : 'none',
            maskStyle : {
                ...maskStyle, 
                // background : '#38c172'
                background : color ?? "#38c172",
            },
            className : 'scan-success-modal',
            content : (
                <>
                    <SuccessTicketUserDetail ticket_user={result?.ticket_user} face_image={face_image} />
                </>
            ),
            footer : null,
        });
    }else{
        failEntranceModal();
    }
    
}

export default {
    modalHandler,
    speech,
    ticketUserModal,

    // result modal
    successEntranceModal,
    failEntranceModal,
    exitEntranceModal,
    maskStyle,
}