import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Select, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import settingFunction from '../setting.function';

const DefaultVoice = () => {
    const [state, setState] = useState({
        loading : false,
        voice : null,
        edit_mode : false,
        voice_list : [],
    });

    const fetchVoices = async () => {
        
        //get voice
        const voices = speechSynthesis.getVoices();
        if(state?.voice_list?.length <= 0){
            setState(state => ({...state, loading : true, }));
            const voice_list = voices?.map((x, index) => ({ label : `${index} - ${x?.name}`, value : `${index}` }));

            const default_voice = localStorage?.getItem("default_voice");
            const voice = voice_list?.length > 0 ? voice_list[default_voice] : null;
            setState(state => ({...state, voice_list : voice_list, voice : voice, loading : false, }));
        }
    }

    const onChange = (voice_id) => {
        const changed_voice = state?.voice_list?.length > 0 ? state?.voice_list[voice_id] : null;
        setState(state => ({...state, voice : changed_voice, }));
    }

    const updateDefaultVoiceSetting = () => {
        const voice = state?.voice;
        if(voice){
            settingFunction?.updateSettingLocal("default_voice", voice?.value)
        }else{
            // delete localstorage
            settingFunction?.deleteLocalStorageSetting("default_voice");
        }
        setState(state => ({...state, edit_mode : false}));
        notification?.success({
            message : 'Successful Update Settings',
            description : `Voice : ${voice?.label ?? "No Default"}`
        })
    }

    useEffect(() => {
        fetchVoices();
    }, [state?.edit_mode])

    return (
        <>
            <div style={{display:'flex', width:'100%'}}>
                <div>
                    <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Voices</span>
                </div>
                <div style={{marginLeft:'auto'}}>
                    <Spin style={{width : '100%', }} spinning={state?.loading} indicator={<LoadingOutlined />}>
                        {
                            state?.edit_mode ?
                            (
                                <>
                                    <Space size={20} wrap>
                                        <div>
                                            <Select
                                            placeholder={'Select Default Entrance'}
                                            options={state?.voice_list}
                                            onChange={onChange}
                                            defaultValue={state?.voice}
                                            style={{minWidth:120,}}
                                            allowClear
                                            />
                                        </div>
                                        <div>
                                            <Space>
                                                <Button
                                                onClick={updateDefaultVoiceSetting}
                                                size={'small'}
                                                icon={<CheckOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                                <Button
                                                onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                                size={'small'}
                                                icon={<CloseOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                            </Space>
                                        </div>
                                    </Space>
                                    
                                </>
                            )
                            :
                            (
                                <>
                                    <Space>
                                        <div>
                                            <span>{(state?.voice?.label ?? <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>No default voice</span>)}</span>
                                        </div>
                                        <div>
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                            size={'small'}
                                            icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                            />
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    </Spin>
                    {/* <Select value={state?.default_voice} onChange={changeVoice} style={{minWidth:120,}} options={list?.voice_list} /> */}
                </div>
            </div>
        </>
    );
}

export default DefaultVoice;