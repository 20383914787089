import moment from 'moment';
import React, { useEffect, useState } from 'react';

const TimeDisplay = () => {
    const [dateTime, setDateTime] = useState(moment().format("YYYY-MM-DD hh:mm:ss"));

    useEffect(() => {
        var timer = setInterval(() => setDateTime(moment().format("YYYY-MM-DD hh:mm:ss")), 1000);

        return function cleanUp(){
            clearInterval(timer);
        }
    }, [])

    return (
        <>
            <div style={{ display: 'flex',}}>
                <div>
                    <span className='pixel-label' style={{color:'#fff'}}>{dateTime}</span>
                </div>
                
            </div>
        </>
    );
}

export default TimeDisplay;