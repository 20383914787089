import { Button, Card, Modal, Popconfirm, Space, Tag } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RightOutlined,} from '@ant-design/icons';

const { confirm } = Modal;

const EntranceCard = ({entrance}) => {
    const [state, setState] = useState({
        is_modal : false,
    })
    const { name : entrance_name, entrance_no, integration_name, entrance_type, } = entrance ?? {};
    const history = useHistory();
    const link = `${integration_name ?? ""}/recognize/${entrance_no}`;

    const redirectLink = (is_default, is_checkout) => {
        const checkout_str = is_checkout ? '?checkout=1' : '';
        if(is_default){
            //set localstorage
            localStorage.setItem("default_entrance", `${entrance_no}${checkout_str}`);
        }

        Modal.destroyAll();
        history.push(`${link}${checkout_str}`);
    }

    const defaultConfirm = async (is_checkout) => {
        confirm({
            title : 'Set entrance as default?',
            content : entrance_name,
            // cancelButtonProps: { style: { display: 'none' } }, // Hide default Cancel button
            // okButtonProps: { style: { display: 'none' } }, // Hide default OK button
            // Custom buttons
            maskClosable : true,
            footer : (
                <>
                    <div style={{display:'flex', width : '100%'}}>
                        <div style={{marginLeft:'auto', marginTop:24,}}>
                            <Space>
                                <Button onClick={() => Modal.destroyAll()} type='text' style={{background:'var(--secondary-button-color)'}}>Cancel</Button>
                                <Button onClick={() => redirectLink(false, is_checkout)}>No</Button>
                                <Button onClick={() => redirectLink(true, is_checkout)} type='primary'>Yes</Button>
                            </Space>
                        </div>
                    </div>
                </>
            )
        })
    }

    return (
        <>
            <div style={{height:'inherit',}}>
                <Card className='entrances-card' style={{height:'100%'}} bodyStyle={{height:'100%'}} bordered={false}>
                    <div style={{display:'flex', flexDirection:'column', height: '100%', }}>
                        <div style={{display:'flex', height:'inherit', }}>
                            <div style={{textAlign:'center', textAlign:'start'}}>
                                <div>
                                    <div>
                                        <span>{entrance_name}</span>
                                    </div>
                                    
                                    <div style={{marginTop:12,}}>
                                        <Space>
                                            <Tag color={entrance_type?.is_entrance ? 'blue' : 'pink'} style={{border:'none'}}>
                                                <span>{entrance_type?.name}</span>
                                            </Tag>

                                            {
                                                integration_name &&
                                                (
                                                    <>
                                                        <Tag color='orange' style={{border:'none'}}>
                                                            <span>{`${integration_name}`}</span>
                                                        </Tag>
                                                    </>
                                                )
                                            }
                                        </Space>
                                    </div>
                                    

                                    
                                </div>
                            </div>
                            <div style={{marginLeft:'auto'}}>
                                <RightOutlined className='right-arrow' />
                            </div>
                        </div>
                        

                        <div style={{marginTop: 'auto',}}>
                            <div style={{marginTop:24,}}>
                                <Space style={{width:'100%'}} direction='vertical'>
                                    <div>
                                        <Button onClick={() => defaultConfirm(false)} type='primary' style={{width:'100%',}}>
                                            Check in
                                        </Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => defaultConfirm(true)} type='text' style={{width:'100%', background : 'var(--secondary-button-color)',}}>
                                            Check out
                                        </Button>
                                    </div>
                                </Space>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

        </>
    );
}

export default EntranceCard;