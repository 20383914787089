import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import authService from '../../services/admin/auth.service';
import { Avatar, Col, Dropdown, Row, Space } from 'antd';
import { LogoutOutlined, SearchOutlined } from '@ant-design/icons';
import IconComponent from '../../helpers/components/icon.component';


const MasterHeader = () => {
    const { innerWidth } = window;
    const history = useHistory();
    const authRedux = useSelector(state => state?.auth);
    const initial = authRedux?.auth?.username?.charAt(0)?.toUpperCase();
    const handleLogout = async () => {
        const logoutResponse = await authService.logout();
        //clear local storage
        if (logoutResponse) {
            history.go(0);
        }
    }
    return (
        <>
            <Row>
                <Col offset={16} span={8}>
                    <div style={{textAlign:'end'}}>
                        <Space size={innerWidth >= 768 ? 40 : 20}>
                            <div>
                                <Link to='/'>
                                    <IconComponent name={'/menus/home.svg'} size={20} />
                                </Link>
                            </div>
                            <div>
                                <Link to='/settings'>
                                    <IconComponent name={'/menus/cog.svg'} size={20} />
                                </Link>
                            </div>
                            <div>
                                <Dropdown
                                arrow
                                menu={{
                                    items: [
                                        {
                                            key: '1',
                                            disabled: true,
                                            style: { padding: '5px 60px' },
                                            label: (
                                                <>
                                                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                                                        <div style={{ paddingBottom: 10 }}>
                                                            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{initial}</Avatar>
                                                        </div>
                                                        <div>
                                                            <span style={{ color: '#000' }}>{authRedux?.auth?.username}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            ),
                                        },
                                        {
                                            type: 'divider',
                                        },
                                        {
                                            key: '2',
                                            icon: <LogoutOutlined />,
                                            onClick: handleLogout,
                                            label: (
                                                <>
                                                    <span>
                                                        Logout
                                                    </span>
                                                </>
                                            ),
                                        },
                                    ]
                                }}
                                trigger={['click']}>
                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', cursor: 'pointer' }}>{initial}</Avatar>
                                </Dropdown>
                            </div>
                            
                        </Space>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default MasterHeader;