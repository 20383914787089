import { Result } from 'antd';
import Lottie from 'lottie-react';
import React from 'react';
import checkLottieJson from '../../../helpers/lotties/check-yes.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import EntranceExit from '../../../helpers/components/entrance_exit';

const EstablishmentExitScan = ({ ticket_user, entrance, integration_response, }) => {
    const image = ticket_user?.image_url;

    // convert data
    const establishment_integration = integration_response && JSON.parse(integration_response);
    const { data, response, } = establishment_integration ?? {};
    const is_show_amount = data?.activityPaymentType === 'Payment';
    return (
        <>
            <Result 
            icon={
                <Lottie animationData={checkLottieJson} loop={false} style={{ height : 160 }}/>
            }
            title={
                <>
                    <div>
                        <div>
                            <span className='label' style={{color : '#fff', fontWeight:'bold', fontSize:24}}>
                                {
                                    entrance?.exit_message
                                }
                            </span>
                        </div>

                        <div style={{marginTop:32,}}>
                            {
                                response?.success && is_show_amount ?
                                (
                                    <>
                                        <EntranceExit exitY={-50} delay={1}>
                                            <div>
                                                <LazyLoadImage
                                                effect='blur'
                                                // placeholder={"/banners/empty-user.png"}
                                                style={{ width:120, height:120, objectFit:'cover' }}
                                                src={'/banners/kidzos_coin.png' }
                                                />
                                            </div>
                                            <div>
                                                <span className='pixel-label' style={{color : '#fff',}}>{data?.activityAmount}</span>
                                            </div>
                                        </EntranceExit>
                                    </>
                                )
                                :
                                (
                                    <>
                                        {/* Hide it first before Safriyal want it live */}
                                        {/* <div>
                                            <Alert
                                            type='error'
                                            message={"Integration Fail"}
                                            description={JSON.stringify(response?.errors)}
                                            />
                                        </div> */}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </>
            }
            />
        </>
    );
}

export default EstablishmentExitScan;