import { combineReducers } from 'redux';
import recognitionReducer from './recognition.reducer';
import authReducer from './auth.reducer';
import kidzaniaReducer from './integration/kidzania.reducer';

const allReducers = combineReducers({
    auth : authReducer,
    recognition : recognitionReducer,

    // for integration
    kidzania : kidzaniaReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;