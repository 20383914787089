import { Avatar, Col, Result, Row, Space } from 'antd';
import React from 'react';
import checkLottieJson from '../../../helpers/lotties/check-yes.json';
import Lottie from "lottie-react";

const ExitScan = ({entrance, result}) => {
    return (
        <>
            <Result 
            icon={
                <Lottie animationData={checkLottieJson} loop={false} style={{ height : 160 }}/>
            }
            title={
                <>
                    <div>
                        <div>
                            <span className='label' style={{color : '#fff', fontWeight:'bold', fontSize:24}}>
                                {
                                    entrance?.exit_message
                                }
                            </span>
                        </div>

                        {
                            entrance?.entrance_type?.is_entrance &&
                            (
                                <>
                                    <div style={{marginTop:12}}>
                                        <Space direction='vertical' style={{justifyContent: 'center'}}>
                                            <div>
                                                <Space size={2} direction='vertical'>
                                                    <div>
                                                        <span className='label' style={{color : 'rgba(255,255,255, 0.7)', fontWeight:'bold', fontSize:18}}>Remaining Checkout</span>
                                                    </div>
                                                    <span className='description' style={{color:(result?.ticket_user?.remaining_checkout_count > 0 ? '#cf1322' : '#fff'), fontWeight:'bold', fontSize:42, textAlign:'center'}}>
                                                        {
                                                            result?.ticket_user?.remaining_checkout_count
                                                        }
                                                    </span>
                                                </Space>
                                            </div>
                                        </Space>
                                        <div style={{marginTop:24,}}>
                                            <Row gutter={[12, 12]}>
                                            {
                                                Object.keys(result?.ticket_user?.grouped_exit_ticket_user_types)?.map((key) => {
                                                    const exit_users = result?.ticket_user?.grouped_exit_ticket_user_types[key] ?? [];
                                                    return (
                                                        <>
                                                            <Col style={{textAlign:'start',}} key={key} span={24}>
                                                                <div className='description'>
                                                                    <span style={{ color : '#fff',  fontWeight:'bold',}}>{`${key} (${exit_users?.length})`}</span>
                                                                </div>
                                                                <div style={{marginTop:12,}}>
                                                                    <div>
                                                                        <Avatar.Group>
                                                                        {
                                                                            exit_users?.map((exit_user, index) => {
                                                                                return (
                                                                                    <Avatar size={40} key={index} src={exit_user?.image_url}/>
                                                                                )
                                                                            })
                                                                        }
                                                                        </Avatar.Group>
                                                                    </div>
                                                                        
                                                                </div>
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                            }
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </>
            }
            />
        </>
    );
}

export default ExitScan;