const initialState = {
    // kidzania
    activity : null,
    payment_type : null,
}

const kidzaniaReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_ACTIVITY':
            return {...state, activity : action.establishment_activity, }
        case 'SET_PAYMENT_TYPE':
            return {...state, payment_type : action.payment_type, }
        default :
            return state;
    }
}

export default kidzaniaReducer;