import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import entranceService from '../../../services/config/entrance.service';
import settingFunction from '../setting.function';

const DefaultEntrance = () => {
    const [state, setState] = useState({
        loading : false,
        entrance : null,
        edit_mode : false,
        entrance_list : [],
    })

    const fetchEntrances = async () => {
        setState(state => ({...state, loading : true, }));
        const entranceResponse = await entranceService?.getEntranceByPark();
        const { parks } = entranceResponse;
        const entrances = parks?.map(x => x?.entrances)?.flat();
  
        const entrance = localStorage?.getItem("default_entrance");
        const selected_entrance = entrances?.find(x => x.entrance_no === entrance);
        setState(state => ({...state, entrance : selected_entrance, entrance_list : entrances, loading : false, }));
    }

    const onChange = (entrance_no) => {
        const changed_entrance = state?.entrance_list?.find(x => x.entrance_no === entrance_no);
        setState(state => ({...state, entrance : changed_entrance, }));
    }

    const updateDefaultEntranceSetting = () => {
        const entrance = state?.entrance;
        if(entrance){
            settingFunction?.updateSettingLocal("default_entrance", state?.entrance?.entrance_no)
        }else{
            // delete localstorage
            settingFunction?.deleteLocalStorageSetting("default_entrance");
        }
        setState(state => ({...state, edit_mode : false}));
        notification?.success({
            message : 'Successful Update Settings',
            description : `Default Entrance : ${entrance?.entrance_no ?? "No Default"}`
        })
    }

    useEffect(() => {
        fetchEntrances();
    }, [])

    return (
        <>
            <div style={{display:'flex', width:'100%'}}>
                <div>
                    <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Default Entrance</span>
                </div>
                <div style={{marginLeft:'auto'}}>
                    <Spin style={{width : '100%', }} spinning={state?.loading} indicator={<LoadingOutlined />}>
                    {
                        state?.edit_mode ?
                        (
                            <>
                                <Space size={20} wrap>
                                    <div>
                                        <Select
                                        placeholder={'Select Default Entrance'}
                                        options={state?.entrance_list?.map((entrance, index) => {
                                            const { entrance_no, name, } = entrance;
                                            return {
                                                value : entrance_no,
                                                label : name,
                                            }
                                        })}
                                        onChange={onChange}
                                        defaultValue={state?.entrance?.entrance_no}
                                        style={{minWidth:120,}}
                                        allowClear
                                        />
                                    </div>
                                    <div>
                                        <Space>
                                            <Button
                                            onClick={updateDefaultEntranceSetting}
                                            size={'small'}
                                            icon={<CheckOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                            />
                                            <Button
                                            onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                            size={'small'}
                                            icon={<CloseOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                            />
                                        </Space>
                                    </div>
                                </Space>
                                
                            </>
                        )
                        :
                        (
                            <>
                                <Space>
                                    <div>
                                        <span>{(state?.entrance?.entrance_no ?? <span style={{color:'var(--secondary-text-color)', fontSize:12,}}>No default entrance</span>)} </span>
                                    </div>
                                    <div>
                                        <Button
                                        onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                        size={'small'}
                                        icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                    </div>
                                    {/* {
                                        state?.entrance &&
                                        (
                                            <>
                                                <div>
                                                    <Popconfirm
                                                    title="Delete default entrance"
                                                    description={`Do you want to delete ${state?.entrance?.entrance_no} as default entrance?`}
                                                    // onConfirm={() => deleteLocalStorageSetting('default_entrance')}
                                                    >
                                                        <Button type='text'>
                                                            <DeleteOutlined style={{color:'var(--secondary-text-color)'}} />
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                            </>
                                        )
                                    } */}
                                </Space>
                                
                            </>
                        )
                    }
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default DefaultEntrance;