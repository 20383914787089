import React, { useEffect, useState } from 'react';
import parkService from '../../../services/config/park.service';
import WebcamTicketRecognize from './webcam_ticket_recognize';
import { LoadingOutlined } from '@ant-design/icons';
import otherService from '../../../services/config/other.service';

const WebcamTicketWrapper = ({ park_code, }) => {
    const [state, setState] = useState({
        loading : false,
        park : null,
        app : null,
    })

    const getPark = async () => {
        setState(state => ({...state, loading : true, }));
        const parkResponse = await parkService?.getPark({
            park_code : park_code,
        });
        const { parks } = parkResponse;
        const appResponse = await otherService?.getApp({
            code : "ADM"
        });
        const { apps } = appResponse;
        const app = apps?.length > 0 ? apps[0] : null;
        setState(state => ({...state, loading : false, park : parks, app : app, }));
    }

    useEffect(() => {
        getPark();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        {
                            (state?.park) &&
                            (
                                <>
                                    <WebcamTicketRecognize park={state?.park} app={state?.app}/>
                                </>
                                
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default WebcamTicketWrapper;