function drawProgressIndicator(a, isScan){
    a.current.style.display = `${!isScan ? 'none' : ''}`
};

var w={color:"white",lineWidth:4,radius:6,visibilityMin:.5};
function x(a){a=a||{};return Object.assign(Object.assign(Object.assign({},w),{fillColor:a.color}),a)}function y(a,c){return a instanceof Function?a(c):a}
function drawRect(a, c, b, recognizing = false) {
    b = x(b);
    a.save();
    
    var d = a.canvas;
    a.beginPath();
    a.lineWidth = y(b.lineWidth, {});
    a.strokeStyle = y(b.color, {});
    a.fillStyle = y(b.fillColor, {});
    
    a.translate(c.xCenter * d.width, c.yCenter * d.height);
    a.rotate(c.rotation * Math.PI / 180);
    a.scale(-1, 1);

    
    // a.rect(
    //     -c.width / 2 * d.width,
    //     -c.height / 2 * d.height,
    //     c.width * d.width,
    //     c.height * d.height,
    // );

    a.arc(0, 0, c.width / 2 * d.width, 0, 2 * Math.PI, false);
    //write text
    a.font = "16px retroGaming";
    a.fillText(recognizing ? 'Stand still' : 'Come closer', -50, 0 + (c.width / 1.5 * d.width));

    a.translate(-c.xCenter * d.width, -c.yCenter * d.height);
    a.stroke();
}

function drawMask(ref, color){
    ref.current.className = `cam-canvas mask-canvas ${color ?? ''}`;
}

function setInitialized(ref){
    ref.current.className = `cam-canvas cam-activate hide`;
}

function setLoaded(ref, initializeRef){
    ref.current.className = `cam-canvas cam-loading hide`;
    initializeRef.current.className = `cam-canvas cam-activate`;
}

export default {
    drawRect,
    drawProgressIndicator,
    drawMask,
    setInitialized,
    setLoaded,
}