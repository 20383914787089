import React from 'react';
import { useParams } from 'react-router-dom';
import WebcamTicketWrapper from './components/webcam_ticket_wrapper';

const FacialRecognitionTicket = () => {
    const { park_code } = useParams();
    return (
        <>
            <WebcamTicketWrapper park_code={park_code} />
        </>
    );
}

export default FacialRecognitionTicket;