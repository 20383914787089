import { Alert, Avatar, Result, Space  } from 'antd';
import React, { useEffect, useState } from 'react';
import Lottie from "lottie-react";
import checkLottieJson from '../../../helpers/lotties/check-yes.json';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SuccessScan = ({ticket_user, account, pass, type, entrance, face_image}) => {
    let image;
    switch(type){
        case 'account' :
            image = account?.face_image;
            break;
        case 'ticket' : 
            image = ticket_user?.image_url;
            break;
        case 'pass' :
            image = pass?.url_image;
            break;
        default :
            break;
    }

    return (
        <>
            <Result 
            icon={
                // <Lottie animationData={checkLottieJson} loop={false} style={{ height : 160, }}/>
                <>
                    <div >
                        <div 
                        className="border-circle" 
                        style={{
                            border : '2px solid rgba(255, 255, 255, 0.6)', 
                            borderRadius:'50%', 
                            padding:8, 
                            maxWidth:'fit-content',
                            margin : 'auto',
                        }}
                        >
                            <Avatar size={140} src={
                                <LazyLoadImage
                                effect='blur'
                                // placeholder={"/banners/empty-user.png"}
                                style={{ width:140, height:140, objectFit:'cover' }}
                                src={ image ?? "/banners/empty-user.png"}
                                />
                                
                            } 
                            />
                        </div>
                    </div>
                </>
            }
            title={
                <>
                    {
                        type === 'account' ?
                        (
                            <>
                                <div>
                                    <div>
                                        <span style={{color : '#fff', fontWeight:'bold', fontSize:18,}}>
                                            {`Welcome`}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='label ticket-label' style={{color : '#fff', fontWeight:'bold', fontSize:28}}>
                                            {account?.full_name ?? account?.username}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                        :
                        type === 'ticket' ?
                        (
                            <>
                                {
                                    entrance?.entrance_type?.is_entrance ?
                                    (
                                        <>
                                            <div>
                                                <div>
                                                    <span style={{color : '#fff', fontWeight:'bold', fontSize:18,}}>
                                                        {`Welcome`}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className='label ticket-label' style={{color : '#fff', fontWeight:'bold', fontSize:28}}>
                                                        {ticket_user?.name ?? ticket_user?.ticket?.name}
                                                    </span>
                                                </div>
                                            </div>

                                            <div style={{marginTop:24}}>
                                                <Space direction='vertical' style={{textAlign:'left', justifyContent: 'center'}}>
                                                    <div>
                                                        <Space size={2} direction='vertical'>
                                                            <div>
                                                                <span className='label' style={{color : 'rgba(255,255,255, 0.7)', fontWeight:'bold', fontSize:14}}>Ticket Type</span>
                                                            </div>
                                                            <span className='description' style={{color:'#fff', fontWeight:'bold', fontSize:14}}>
                                                                {
                                                                    `${(ticket_user?.ticket_user_type?.name)} 
                                                                    ${ticket_user?.ticket_user_type?.max_age ? 
                                                                        `(Age: ${(ticket_user?.ticket_user_type?.min_age)}-${(ticket_user?.ticket_user_type?.max_age)})` 
                                                                        :
                                                                        `(Age: ${(ticket_user?.ticket_user_type?.min_age)}-100+)`
                                                                    }`
                                                                }
                                                            </span>
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space size={2} direction='vertical'>
                                                            <div>
                                                                <span className='label' style={{color : 'rgba(255,255,255, 0.7)', fontWeight:'bold', fontSize:14}}>Event</span>
                                                            </div>
                                                            <span className='description' style={{color:'#fff', fontWeight:'bold', fontSize:14}}>
                                                                {`${ticket_user?.ticket?.park?.name} (${moment((ticket_user?.open_date ?? ticket_user?.ticket?.visit_date)).format("Do MMM YYYY")})`}
                                                            </span>
                                                        </Space>
                                                    </div>
                                                </Space>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div>
                                                <div>
                                                    <span className='label' style={{color : '#fff', fontWeight:'bold', fontSize:24}}>{entrance?.display_message}</span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                        :
                        type === 'pass' &&
                        (
                            <>
                                <div>
                                    <div>
                                        <span style={{color : '#fff', fontWeight:'bold', fontSize:18,}}>
                                            {`Welcome`}
                                        </span>
                                    </div>
                                    <div>
                                        <span className='label ticket-label' style={{color : '#fff', fontWeight:'bold', fontSize:28}}>
                                            { pass?.name }
                                        </span>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </>
            }
            />
        </>
    );
}

export default SuccessScan;