import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal, Space, Spin, notification } from 'antd';

// component
import BitLoader from '../../../helpers/components/bit_loader.component';
import TimeDisplay from './time_display';

//face detections
import { FaceDetection } from '@mediapipe/face_detection';
import * as cam from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';

// helper
import canvasHelper from '../../../helpers/functions/canvas.helper';
import faceHelper from '../../../helpers/functions/face.helper';

// service
import recognitionService from '../../../services/recognition/recognition.service';

// lottie
import Lottie from 'lottie-react';
import clickJson from '../../../helpers/lotties/click.json';
import facialJson from '../../../helpers/lotties/facial.json';
import { CloseOutlined, CopyFilled, EditFilled, EditOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

const WebcamTicketRecognize = ({ park, app, }) => {
    const webcamRef = useRef();
    const canvasRef = useRef();
    const spinnerRef = useRef();
    const maskRef = useRef();
    const dateRef = useRef();
    const modalCloseRef = useRef();
    const initializeRef = useRef();
    const loadRef = useRef();

    // item ref
    const ticketNoRef = useRef();

    // let status = 'initialize';
    let status = 'loading';
    let face_result = null;
    var camera = null;

    // calculate responsive width and height
    const { innerWidth, innerHeight } = window;
    const calculated_width = (innerWidth - (innerWidth * 0.3));
    const is_full_screen = innerHeight > innerWidth;
    const responsive_width = is_full_screen ? innerWidth : calculated_width;
    const responsive_height = (innerHeight);

    function onResults(results) {
        //setting of height and width of canvas,
        canvasRef.current.width = webcamRef.current.video.videoWidth;
        canvasRef.current.height = webcamRef.current.video.videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext('2d');
        canvasCtx.save();

        // Draw the overlays.
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height
        );

        if (results.detections.length > 0) {
            const boundingBox = results.detections[0].boundingBox;
            const size = (boundingBox?.width * boundingBox?.height);
            //size to scan -> 0.16
            const isScan = size > 0.055; //this set to adjustable using admin dashboard in future

            // Rounded rectangle with four different radius
            const { width, height, xCenter : x, yCenter : y } = boundingBox ?? {};
            canvasHelper.drawRect(canvasCtx, boundingBox, { color: isScan ? 'green' : 'orange', lineWidth: 3}, isScan);
            
            const face_detected_count = results?.detections?.length;
            const isStartScan = isScan && face_detected_count && status == 'idle';
            
            if(isStartScan){
                //capture image
                canvasHelper.drawProgressIndicator(spinnerRef, true);
                canvasHelper.drawMask(maskRef, 'load');
                const captureImage = capturePicture().then((re) => {
                    const { result, pictureSrc, } = re;
                    faceHelper.ticketUserModal(result, pictureSrc);
                    setDateRef(1001);
                    face_result = result;
                    ticketNoRef.current.setAttribute('ticketno-attribute', result?.ticket_user?.ticket_user_no)
                    canvasHelper.drawMask(maskRef, '');
                    canvasHelper.drawProgressIndicator(spinnerRef, false);
                    
                });
            }
        }
        
        canvasCtx.restore();
    }

    async function capturePicture(){
        status = 'recognize';
        const pictureSrc = webcamRef.current.getScreenshot();
        const result = await recognizePicture(pictureSrc);

        return {
            result,
            pictureSrc
        };
    }

    //run recognize image
    const recognizePicture = async (picture) => {
        //using the picture to send recognize
        const binaryImage = await fetch(picture);
        const trim = await binaryImage.blob();
        const recognitionResponse = await recognitionService.getTicketUserByFace({
            image : trim,
            park_code : park?.park_code,
        }).catch((err) => {
            // dispatch(set_subject(null));
            // setFaceResult(faceResult => ({...faceResult, name : null, distance : null, loading  : false,}));
        });

        const { result, ticket_user, } = recognitionResponse ?? {};

        return {
            result, ticket_user,
        };
    }

    // face detections
    const initializeFaceDetection = async () => {
        const faceDetection = new FaceDetection({
            locateFile: (file) => {
            //   return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`;
            return `/js/facial-recognition/${file}`;
            },
        });

        faceDetection.setOptions({
            // selfieMode: true,
            model: "short",
            minDetectionConfidence: 0.8
            // modelSelection: 0,
        });

        faceDetection.onResults((results) => {
            const isScan = onResults(results);
            if(status == 'loading'){
                // set a loader
                status = 'initializing';
                canvasHelper?.setLoaded(loadRef, initializeRef);   
            }
        });

        const videoElement = webcamRef.current.video;
        if (webcamRef.current) {
            camera = new cam.Camera(videoElement, {
            onFrame: async () => {
                await faceDetection.send({ image: videoElement });
            },
            });
            camera.start();
        }
    };

    const closeModal = async () => {
        status = 'waiting';
        Modal.destroyAll();
        setDateRef(0);
        ticketNoRef?.current?.setAttribute("ticketno-attribute", null);
        setTimeout(() => {
            status = 'idle'; // delay 1.5 seconds before start scanning again
        }, 1500);
    }
    
    const setDateRef = (zIndex) => {
        modalCloseRef.current.className = `modal-close-icon ${zIndex > 0 ? '' : 'hide'}`;
        dateRef.current.className = `date-detail cam-canvas z${zIndex}`;
    }

    const editTicketUser = () => {
        const ticket_user_no = ticketNoRef?.current?.getAttribute("ticketno-attribute");
        const target_domain = `${app.url}ticket/user/field/${ticket_user_no}`;
        window.open(target_domain, '_blank');
    }

    const onCopy = () => {
        const ticket_user_no = ticketNoRef?.current?.getAttribute("ticketno-attribute");
        // Copy the text inside the text field
        navigator.clipboard.writeText(ticket_user_no);
        notification.success({
            message : 'Ticket Number Copied Successfully!',
            description : ticket_user_no,
            placement : 'bottomLeft',
        })
    }

    const clickInitialize = () => {
        faceHelper?.speech(`Welcome`);
        canvasHelper?.setInitialized(initializeRef);
        setTimeout(() => {
            status = 'idle';
        }, 1500);
    }

    useEffect(() => {
        initializeFaceDetection();
    }, []); //this count is to reinitiaze the detection

    return (
        <>
            <div>
                <Webcam
                ref={webcamRef}
                audio={false}
                className="face-box green"
                mirrored
                screenshotQuality={1}
                screenshotFormat='image/jpeg'
                style={{
                    position: "absolute",
                    margin: "auto",
                    left: 0,
                    right: 0,
                    width : responsive_width,
                    height : responsive_height,
                }}
                videoConstraints={{
                    facingMode : 'user'
                }}
                />

                <canvas
                ref={canvasRef}
                className="cam-canvas face-box green"
                style={{width : responsive_width, height: responsive_height, }}
                />

                {/* for item stacked on camera start from here */}
                <div
                ref={maskRef}
                className="cam-canvas mask-canvas"
                style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}
                >
                    <div ref={spinnerRef} style={{display:'none'}}>
                        <div>
                            <BitLoader size={10} />
                            <div style={{textAlign:'center', marginTop:'50%'}}><span className='pixel-label' style={{color:'#fff', fontSize:16}}>Recognizing</span></div>
                        </div>
                    </div>
                </div>

                <div ref={dateRef} className='date-detail cam-canvas' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height, padding : 12, }}>
                    <div style={{ background:'rgba(0, 0, 0, 0.6)', padding : 12, borderRadius:8, }}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{ textAlign:'start', color : '#fff'}}>
                                <div>
                                    <span className='pixel-label' style={{color:'#fff',}}>{park?.name}</span>
                                </div>
                            </div>
                            <div style={{marginLeft : 'auto'}}>
                                <div ref={modalCloseRef} className='modal-close-icon hide'>
                                    <div ref={ticketNoRef} ticketno-attribute={null} />
                                    <Space>
                                        <div>
                                            <span className='pixel-label' style={{color:'#fff',}}>
                                                <Button onClick={onCopy} type='text' icon={<CopyFilled />} style={{color : '#fff'}} />
                                            </span>
                                        </div>
                                        <div>
                                            <span className='pixel-label' style={{color:'#fff',}}>
                                                <Button onClick={editTicketUser} type='text' icon={<EditFilled style={{color : '#fff',}} />} />
                                            </span>
                                        </div>
                                        <div>
                                            <span className='pixel-label' style={{color:'#fff',}}>
                                                <Button onClick={closeModal} type='text' icon={<CloseOutlined style={{color : '#fff',}} />} />
                                            </span>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={loadRef} className='cam-canvas cam-loading' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={facialJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <div className='bit-loader dark' style={{width : 10, height : 10, position :'relative'}}></div>
                        </div>
                    </div>
                </div>
                
                <div ref={initializeRef} className='cam-canvas cam-activate hide' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div onClick={clickInitialize} style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={clickJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <span className='pixel-label' style={{ fontSize:24, color : 'rgba(0, 0, 0, 0.8)' }}>{'Click to initialize'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebcamTicketRecognize;