import React from 'react';
import SettingList from './setting_list';
import { Card, Space } from 'antd';
import MqttSettingList from './mqtt_setting_list';

const SettingLayout = () => {


    return (
        <>
            <div>
                <div style={{textAlign:'start'}}>
                    <div>
                        <span
                        className='ticket-label ticket-title h1'
                        style={{
                            fontWeight:'bold',
                            fontSize : 20,
                        }}
                        >
                            {"Settings"}
                        </span>
                    </div>
                </div>
                
                <div style={{marginTop: 12,}}>
                    <Space size={24} style={{width : '100%',}} direction='vertical'>
                        <div>
                            <div style={{textAlign:'start',}}>
                                <span style={{color : 'var(--secondary-text-color)', fontWeight:500,}}>General Entrance Setting</span>
                            </div>
                            <div style={{marginTop:12,}}>
                                <Card bodyStyle={{padding:0,}} className='entrances-card' style={{height:'100%'}} bordered={false}>
                                    <SettingList />
                                </Card>
                            </div>
                        </div>
                        <div>
                            <div style={{marginTop:12,}}>
                                <MqttSettingList />
                            </div>
                        </div>
                    </Space>
                </div>
            </div>
        </>
    );
}

export default SettingLayout;