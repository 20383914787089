import React, { useEffect, useState } from 'react';
import entranceService from '../../../services/config/entrance.service';
// import WebcamRecognize2 from './webcam_recognize2';
import { Alert, Collapse, Modal, notification } from 'antd';
import { CaretRightFilled, LoadingOutlined } from '@ant-design/icons';
import KidzaniaWebcamRecognize from './kidzania_webcam_recognize';
import MqttClass from '../../../helpers/functions/mqtt.helper';

const { Panel } = Collapse;
const KidzaniaRecognize2Wrapper = ({entrance_no, is_checkout}) => {
    const [state, setState] = useState({
        loading : false,
        entrance : null,
        mqtt : null,
    });

    const fetchEntrance = async () => {
        setState(state => ({...state, loading : true,}))
        const entranceResponse = await entranceService.getEntrance({
            entrance_no,
        });
        
        if(entranceResponse){
            const { entrances } = entranceResponse;
            setState(state => ({...state, entrance : entrances[0] ?? null, loading : false, }));
        }
    }

    //calculate refresh
    const refreshTimer = () => {
        setInterval(() => {
            window.location.reload();
            // setState(state => ({...state, count : state?.count + 1}));  
        }, 1000 * 60 * 60);
         // call interval every hour
    }

    useEffect(() => {
        fetchEntrance();
        refreshTimer();
    }, []);

    const mqttNotification = ({ is_connected, mqtt_topic, message, }) => {
        notification?.[(is_connected ? 'success' : 'error')]({
            key : 'mqtt',
            closeIcon : (null),
            placement : 'bottomRight',
            // duration : (is_connected ? 5 : null),
            duration : null,
            message : (
                <>
                    {
                        is_connected ?
                        (
                            <>
                                <span style={{ color : '#fff', fontSize : 14, }}>
                                    {mqtt_topic ?? "Missing Topic"}
                                </span>
                            </>
                        )
                        :
                        (
                            <>
                                <Collapse
                                ghost
                                className='status-notification-collapse'
                                // defaultActiveKey={['1']}
                                style={{ padding : 0, }}
                                expandIcon={({ isActive }) => <CaretRightFilled style={{ color : '#fff',}} rotate={isActive ? 90 : 0} />}
                                >
                                    <Panel style={{ padding : 0, }} header={(<><span style={{ color : '#fff', fontSize : 14, }}>{mqtt_topic}</span></>)} key="1">
                                        <Alert 
                                        type='error'
                                        style={{ padding : 12, }}
                                        description={(
                                            <>
                                                <span>{message?.toString()}</span>
                                            </>
                                        )}
                                        />
                                        
                                    </Panel>
                                </Collapse>
                            </>
                        )
                    }
                </>
            ),
            style : {
                background : '#323949',
                padding : '8px 12px 2px 12px',
            }
        })
    }

    const initializeMqtt = async () => {
        const mqtt_host = (localStorage?.getItem("mqtt_host"));
        const mqtt_username = (localStorage?.getItem("mqtt_username"));
        const mqtt_password = (localStorage?.getItem("mqtt_password"));
        const mqtt_topic = localStorage?.getItem("mqtt_topic");
        const mqtt = new MqttClass(mqtt_host, mqtt_username, mqtt_password, mqtt_topic);

        if(mqtt_host){
            if(mqtt?.client){
                // Event handler for successful connection
                mqtt?.client?.on('connect', (connection) => {
                    // if success connect, subsribe to topic
                    console.log(`connected to ${mqtt_host}`, connection);
                    console.log(`publishing to ${mqtt_topic}`);
                    mqttNotification({ is_connected : true, mqtt_topic, });
                })
    
                // Event handler for connection failure
                mqtt?.client?.on('error', (error) => {
                    mqttNotification({ is_connected : false, mqtt_topic, message : error, });
                    console.log('Connection failed:', error);
                });
    
                // Event handler for connection stream failure
                mqtt?.client?.['stream']?.on('error', (error) => {
                    console.warn('Connection Stream failed:', error);
                    mqttNotification({ is_connected : false, mqtt_topic : mqtt_topic, message : error, });
                })
            }else{
                mqttNotification({ is_connected : false, mqtt_topic : mqtt_topic, message : mqtt?.connect_error, });
            }
        }

        setState(state => ({...state, mqtt : mqtt,}));
        return mqtt;
    }
    
    useEffect(() => {
        const initialized_mqtt = initializeMqtt();

        // Clean up on component unmount
        return () => {
            initialized_mqtt.then((mqtt) => {
                mqtt?.client?.end();
                notification?.destroy();
                setState(state => ({...state, mqtt : null, }));
            })
        }
    }, [localStorage?.getItem("mqtt_host"), localStorage?.getItem("mqtt_topic"),])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        {
                            state?.entrance ?
                            (
                                <>
                                    <KidzaniaWebcamRecognize entrance={state?.entrance} is_checkout={is_checkout} mqtt={state?.mqtt} />
                                </>
                                
                            )
                            :
                            (
                                <>
                                    {/* No Entrance Found */}
                                </>
                            )
                        }
                    </>
                )
            }
            
            
        </>
    );
}

export default KidzaniaRecognize2Wrapper;