import React, { useEffect, useRef, useState } from 'react';
import * as cam from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';
import { Alert, Card, Col, Drawer, Input, Modal, Row, notification } from 'antd';

//face detections
import { FaceDetection } from '@mediapipe/face_detection';

import canvasHelper from '../../../helpers/functions/canvas.helper';
import TimeDisplay from '../../face_recognition/components/time_display';
import BitLoader from '../../../helpers/components/bit_loader.component';
import recognitionService from '../../../services/recognition/recognition.service';
import faceHelper from '../../../helpers/functions/face.helper';

// lottie
import Lottie from 'lottie-react';
import clickJson from '../../../helpers/lotties/click.json';
import facialJson from '../../../helpers/lotties/facial.json';
import scannerJson from '../../../helpers/lotties/scanner.json';

// item
import EntranceMotion from '../../../helpers/components/entrance_motion';
import kidzoftService from '../../../services/integration/kidzoft.service';
import { CloseOutlined } from '@ant-design/icons';
import KidzaniaActivity from './kidzania_activity';
import KidzaniaPaymentType from './kidzania_payment_type';
import { useSelector } from 'react-redux';
import kidzania from '../functions/kidzania';


const KidzaniaWebcamRecognize = ({ entrance, is_checkout, mqtt, }) => {
    const webcamRef = useRef();
    const canvasRef = useRef();
    const spinnerRef = useRef();
    const maskRef = useRef();
    const initializeRef = useRef();
    // loading for cameras
    const loadRef = useRef();
    // payment
    const paymentTypeRef = useRef();
    // barcode reader
    const barcodeScannerRef = useRef();
    const barcodeInputRef = useRef();
    const errorAlertRef = useRef();

    // let status = 'initialize';
    let status = 'loading';
    let face = null;
    var camera = null;
    let barcode = null;

    // for payment type state
    let modalObj = {};
    let timer = null;

    // calculate responsive width and height
    const { innerWidth, innerHeight } = window;
    const calculated_width = (innerWidth - (innerWidth * 0.3));
    const is_full_screen = innerHeight > innerWidth;
    const responsive_width = is_full_screen ? innerWidth : calculated_width;
    const responsive_height = (innerHeight);

    function onResults(results) {
        //setting of height and width of canvas,
        canvasRef.current.width = webcamRef.current.video.videoWidth;
        canvasRef.current.height = webcamRef.current.video.videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext('2d');
        canvasCtx.save();

        // Draw the overlays.
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height
        );

        if (results.detections.length > 0) {
            const boundingBox = results.detections[0].boundingBox;
            const size = (boundingBox?.width * boundingBox?.height);
            //size to scan -> 0.16
            const distance_setting = localStorage?.getItem("default_distance");
            const isScan = size > (distance_setting ?? 0.055); //this set to adjustable using admin dashboard in future

            // Rounded rectangle with four different radius
            const { width, height, xCenter : x, yCenter : y } = boundingBox ?? {};
            canvasHelper.drawRect(canvasCtx, boundingBox, { color: isScan ? 'green' : 'orange', lineWidth: 3}, isScan);
            
            const face_detected_count = results?.detections?.length;
            const isStartScan = isScan && face_detected_count && status == 'idle';

            if(isStartScan){
                //capture image
                canvasHelper.drawProgressIndicator(spinnerRef, true);
                canvasHelper.drawMask(maskRef, 'load');
                const captureImage = capturePicture().then(async (re) => {
                    const { result, pictureSrc, } = re;
                    const is_entrance = entrance?.entrance_type?.is_entrance;
                    if(is_entrance){
                        modalObj = {
                            result, entrance, pictureSrc,
                        }

                        successEntrance();
                        if(result?.result){
                            // integration
                            const ticket_user = result?.ticket_user;
                            const { is_checkout, ticket_user_id } = ticket_user ?? {};
                            const kidzoft_park_entrance = await kidzoftParkEntrance(ticket_user_id, is_checkout);
                        }
                    }else{
                        if(result?.result){
                            modalObj = {
                                result, entrance, pictureSrc,
                            }
                            await onSelectPaymentType();
                        }else{
                            // no face found
                            successEntrance();
                        }
                    }

                    canvasHelper.drawMask(maskRef, '');
                    canvasHelper.drawProgressIndicator(spinnerRef, false);
                });
            }
        }
        
        canvasCtx.restore();
    }

    async function capturePicture(){
        status = 'recognize';
        const pictureSrc = webcamRef.current.getScreenshot();
        const result = await recognizePicture(pictureSrc);

        setTimeout(() => {
            status = 'waiting';
            // Modal.destroyAll();

            setTimeout(() => {
                // status = 'idle'; // delay 1.5 seconds before start scanning again
            }, 1500);
        }, 1500);

        return {
            result,
            pictureSrc
        };
    }

    //run recognize image
    const recognizePicture = async (picture) => {
        //using the picture to send recognize
        const binaryImage = await fetch(picture);
        const trim = await binaryImage.blob();
        const recognitionResponse = await recognitionService.recognizeImage({
            image : trim,
            entrance_no : entrance?.entrance_no,
            is_force_checkout : is_checkout ?? 0,
            park_code : entrance?.park?.park_code,
        }).catch((err) => {
            // dispatch(set_subject(null));
            // setFaceResult(faceResult => ({...faceResult, name : null, distance : null, loading  : false,}));
        });

        const { result, ticket_user, account, pass, type, detect_face, } = recognitionResponse ?? {};

        return {
            result, ticket_user, account, pass, type, detect_face,
        };
    }

    // face detections
    const initializeFaceDetection = async () => {
        const faceDetection = new FaceDetection({
            locateFile: (file) => {
            //   return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`;
            return `/js/facial-recognition/${file}`;
            },
        });

        faceDetection.setOptions({
            // selfieMode: true,
            model: "short",
            minDetectionConfidence: 0.8
            // modelSelection: 0,
        });

        faceDetection.onResults((results) => {
            const isScan = onResults(results);

            if(status == 'loading'){
                // set a loader
                status = 'initializing';
                canvasHelper?.setLoaded(loadRef, initializeRef);   
            }
        });

        const videoElement = webcamRef.current.video;
        if (webcamRef.current) {
            camera = new cam.Camera(videoElement, {
            onFrame: async () => {
                await faceDetection.send({ image: videoElement });
            },
            //   width: 480,
            //   height: 640,
            });
            camera.start();
        }
    };

    const clickInitialize = () => {
        faceHelper?.speech(`Welcome`);
        canvasHelper?.setInitialized(initializeRef);

        setTimeout(() => {
            status = 'idle';
        }, 1500);
    }

    const setBarcodeScannerModal = (is_show) => {
        barcodeScannerRef.current.className = `cam-canvas cam-barcode-scanner ${is_show ? 'active' : 'hide'}`;
        clearBarcodeInput();
    }

    const clearBarcodeInput = () => {
        errorAlertRef.current.className = `scan-alert hide`;
        const barcode_input_el = document.querySelector("#barcode_input");
        barcode_input_el.value = "";
        barcode_input_el?.focus();
    }

    const closeBarcodeScannerModal = () => {
        barcodeScannerRef.current.className = `cam-canvas cam-barcode-scanner hide`;
        setTimeout(() => {
            status = 'idle';
        }, 1500);
    }

    const scanCard = async (input) => {
        canvasHelper.drawProgressIndicator(spinnerRef, true);
        canvasHelper.drawMask(maskRef, 'load');
        const { park_id } = entrance?.park;
        const bankAccountResponse = await kidzoftService?.getBankAccount(input, park_id);
        const { bank_account } = bankAccountResponse;

        if(bank_account){
            setTimeout(async () => {
                canvasHelper.drawMask(maskRef, '');
                canvasHelper.drawProgressIndicator(spinnerRef, false);
                // successEntrance();
                
                // integration start here
                const { result, entrance, pictureSrc } = modalObj;
                const ticket_user = result?.ticket_user;
                const { is_checkout, ticket_user_id } = ticket_user ?? {};
                const { entrance_id } = entrance ?? {};

                if(bank_account){
                    const establishmentAccessResponse = await kidzoftEntablishmentAccess({
                        ticket_user_id,
                        payment_type : 2,
                        card_number : input,
                        entrance_id,
                        is_checkout,
                    }).then((result) => {
                        const { kidzoftEstablishmentAccess } = result;
                        successEstablishmentEntrance(kidzoftEstablishmentAccess);
                    }).catch((err) =>{
                        notification?.error({
                            message : 'Oopss, Something wrong happens',
                            description : err,
                        })
                    });
                }
            }, 500);
        }else{
            // failPayment();
            setTimeout(() => {
                canvasHelper.drawMask(maskRef, '');
                canvasHelper.drawProgressIndicator(spinnerRef, false);
                errorAlertRef.current.className = `scan-alert`;
            }, 500);
        }

        clearBarcodeInput();
    }

    const doneTyping = (input) => {
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            scanCard(input);
        }, 300);
    }

    const onSelectPaymentType = async () => {
        const payment_type_str = localStorage?.getItem("kidzania_payment_type");
        const activity_str = localStorage?.getItem("kidzania_activity");
        let payment_type;
        if(payment_type_str){
            payment_type = JSON.parse(payment_type_str);
        }
        let activity;
        if(activity_str){
            activity = JSON.parse(activity_str);
        }

        const { type } = modalObj?.result;
        if(type === 'pass'){
            successPassEntrance();
            return;
        };

        if(payment_type?.is_card_number){
            // if is card
            setBarcodeScannerModal(true);
        }else{
            // if is cash
            const { result, entrance, pictureSrc } = modalObj;
            const ticket_user = result?.ticket_user;
            const { is_checkout, ticket_user_id } = ticket_user ?? {};
            const { entrance_id } = entrance ?? {};
            const establishmentAccessResponse = await kidzoftEntablishmentAccess({
                ticket_user_id,
                payment_type : 1,
                entrance_id,
                is_checkout,
            }).then((result) => {
                const { kidzoftEstablishmentAccess } = result;
                successEstablishmentEntrance(kidzoftEstablishmentAccess);
            }).catch((err) =>{
                notification?.error({
                    message : 'Oopss, Something wrong happens',
                    description : err,
                })
            });
        }
    }

    const successPassEntrance = () => {
        const { result, entrance, pictureSrc } = modalObj;
        kidzania?.modalHandler(result, entrance, pictureSrc);

        setTimeout(() => {
            status = 'waiting';
            Modal.destroyAll();
            setBarcodeScannerModal(false);
            modalObj = {};
            
            setTimeout(() => {
                status = 'idle'; // delay 1.5 seconds before start scanning again
            }, 1500);
        }, 1500);
    }

    const successEstablishmentEntrance = (establishment_integration_result) => {
        const { response } = establishment_integration_result ?? {};
        // proccess checking handler
        const { result, entrance, pictureSrc } = modalObj;
        kidzania?.modalHandler(result, entrance, pictureSrc, response);

        setTimeout(() => {
            status = 'waiting';
            Modal.destroyAll();
            setBarcodeScannerModal(false);
            modalObj = {};
            
            setTimeout(() => {
                status = 'idle'; // delay 1.5 seconds before start scanning again
            }, 1500);
        }, 1500);
    }

    const successEntrance = () => {
        // proccess checking handler
        const { result, entrance, pictureSrc } = modalObj;
        faceHelper.modalHandler(result, entrance, pictureSrc,);

        // mqtt
        if(result?.result){
            mqtt?.publish({
                ticket_user_no : result?.ticket_user?.ticket_user_no,
                pass_code : result?.pass?.pass_code,
            });
        }

        setTimeout(() => {
            status = 'waiting';
            Modal.destroyAll();
            setBarcodeScannerModal(false);
            modalObj = {};
            
            setTimeout(() => {
                status = 'idle'; // delay 1.5 seconds before start scanning again
            }, 1500);
        }, 1500);
    }

    const failPayment = () => {
        faceHelper.modalHandler();
    }

    // integration-----------------------------------------------------------------------------
    const kidzoftParkEntrance = async (ticket_user_id, is_checkout,) => {
        const parkEntranceResponse = await kidzoftService?.parkEntrance({
            ticket_user_id,
            is_checkout,
        })

        return parkEntranceResponse;
    }

    const kidzoftEntablishmentAccess = async ({ ticket_user_id, payment_type, card_number, entrance_id, is_checkout, activity_id, }) => {
        let establishmentAccessResponse
        if(ticket_user_id){ // ignore if its account scan
            establishmentAccessResponse = await kidzoftService?.establishmentAccess({
                ticket_user_id,
                payment_type,
                card_number,
                entrance_id,
                is_checkout,
                activity_id, // kidzania establishment activity
            });
        }
        return establishmentAccessResponse;
    }

    useEffect(() => {
        // initializeFaceMesh();
        initializeFaceDetection();
    }, []); //this count is to reinitiaze the detection

    return (
        <>
            <div>
                <Webcam
                ref={webcamRef}
                audio={false}
                // ref={webcamRef}
                className="face-box green"
                mirrored
                screenshotQuality={1}
                screenshotFormat='image/jpeg'
                style={{
                    position: "absolute",
                    margin: "auto",
                    // textAlign: "center",
                    // top: 100,
                    left: 0,
                    right: 0,
                    // display:'none',
                    width : responsive_width,
                    height : responsive_height,
                    // display : 'none'
                }}
                videoConstraints={{
                    facingMode : 'user'
                }}
                />

                <canvas
                ref={canvasRef}
                className="cam-canvas face-box green"
                style={{width : responsive_width, height: responsive_height, }}
                />


                {/* for item stacked on camera start from here */}
                <div
                ref={maskRef}
                className="cam-canvas mask-canvas"
                style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}
                >
                    <div ref={spinnerRef} style={{display:'none'}}>
                        <div>
                            <BitLoader size={10} />
                            <div style={{textAlign:'center', marginTop:'50%'}}><span className='pixel-label' style={{color:'#fff', fontSize:16}}>Recognizing</span></div>
                        </div>
                        {/* <img src='/loader/spin.svg' style={{width : 'inherit', height : 'inherit'}} /> */}
                    </div>
                </div>
                
                {/* item displaying on top of webcam scanner */}
                {
                    entrance?.entrance_type?.is_entrance ?
                    (
                        <>
                            <div className='date-detail cam-canvas' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height, padding : 12, }}>
                                <div style={{ background:'rgba(0, 0, 0, 0.6)', padding : 12, borderRadius:8, cursor :'pointer', }}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{ textAlign:'start', color : '#fff'}}>
                                            <div>
                                                <span className='pixel-label' style={{color:'#fff',}}>{entrance?.name}</span>
                                            </div>
                                            {/* <Button onClick={handleFullScreen} type='text' icon={<ExpandOutlined style={{color : '#fff'}} />}></Button> */}
                                        </div>
                                        <div style={{marginLeft : 'auto'}}>
                                            <TimeDisplay /> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div ref={paymentTypeRef} className='cam-canvas cam-payment-type' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height, }} >
                                <div>
                                    <KidzaniaActivity entrance={entrance} />
                                </div>
                                <div>
                                    <KidzaniaPaymentType />
                                </div>
                            </div>
                        </>
                    )
                }

                <div ref={loadRef} className='cam-canvas cam-loading' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={facialJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <div className='bit-loader dark' style={{width : 10, height : 10, position :'relative'}}></div>
                        </div>
                    </div>
                </div>
                
                <div ref={initializeRef} className='cam-canvas cam-activate hide' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div onClick={clickInitialize} style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={clickJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <span className='pixel-label' style={{ fontSize:24, color : 'rgba(0, 0, 0, 0.8)' }}>{'Click to initialize'}</span>
                        </div>
                    </div>
                </div>

                <div ref={barcodeScannerRef} className='cam-canvas cam-barcode-scanner hide' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height, background :'#fff'}}>
                    <div style={{ display: 'flex', justifyContent:'end', width : '100%', height:60}}>
                        <div onClick={closeBarcodeScannerModal} style={{padding : 24, cursor : 'pointer',}}>
                            <CloseOutlined size={48} style={{color:'var(--secondary-text-color)', fontSize: 24,}} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'calc(100% - 60px)', width:'100%' }}>
                        <div>
                            <div>
                                <Lottie animationData={scannerJson} loop style={{ height : 120, }} />
                            </div>
                            
                            <div>
                                <span className='pixel-label' style={{color:'var(--secondary-text-color)'}}>Scan Card Number</span>
                            </div>

                            <div ref={errorAlertRef} className='scan-alert hide' style={{marginTop:12,}}>
                                <Alert message="Scan Again" type='error'/>
                            </div>
                        </div>
                        <div>
                            <input
                            inputMode='none'
                            style={{opacity: 0,}}
                            ref={barcodeInputRef}
                            onChange={(e) => doneTyping(e?.target?.value)}
                            id="barcode_input"
                            autoFocus
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KidzaniaWebcamRecognize;