import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, List, Space, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import settingFunction from '../setting.function';

const MqttSettingList = () => {
    const [form] = Form.useForm();
    const [state, setState] = useState({
        edit_mode : false,
    })

    const saveMqttSetting = () => {
        const form_obj = (form?.getFieldsValue())
        Object.keys(form_obj).forEach(key => {
            const value = form_obj[key];
            if(value){
                // set to localstorage
                settingFunction?.updateSettingLocal(key, value);
            }else{
                // delete from localstorage
                settingFunction?.deleteLocalStorageSetting(key);
            }
        });

        notification?.success({
            message : 'Successful Update MQTT Settings',
        })

        setState(state => ({...state, edit_mode : false,}))
    }

    const initializeMqttDefaultValue = () => {
        const form_obj = (form?.getFieldsValue())
        let form_fields = {};
        Object.keys(form_obj).forEach(key => {
            const value = localStorage?.getItem(key);
            form_fields[key] = value;
        });
        form.setFieldsValue(form_fields);
    }

    useEffect(() => {
        initializeMqttDefaultValue();
    }, [])

    return (
        <>
            <Form
            form={form}
            onFinish={saveMqttSetting}
            >
                <div style={{textAlign:'start', display : 'flex', alignItems:'center', }}>
                    <div>
                        <span style={{color : 'var(--secondary-text-color)', fontWeight:500,}}>MQTT Setting</span>
                    </div>
                    <div style={{marginLeft:'auto'}}>
                        {
                            state?.edit_mode ?
                            (
                                <>
                                    <Space>
                                        <Button
                                        onClick={() => form.submit()}
                                        size={'small'}
                                        icon={<CheckOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                        <Button
                                        onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                        size={'small'}
                                        icon={<CloseOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                        />
                                    </Space>
                                </>
                            )
                            :
                            (
                                <>
                                    <Button
                                    onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                    size={'small'}
                                    icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                    />
                                </>
                            )
                        }
                    </div>
                </div>
                <div style={{marginTop:12,}}>
                    <Card bodyStyle={{padding:0,}} className='entrances-card' style={{height:'100%'}} bordered={false}>
                        <List>
                            <List.Item>
                                <div style={{display:'flex', width:'100%', alignItems:'center',}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Host</span>
                                    </div>
                                    <div style={{marginLeft:'auto',}}>
                                        <Form.Item name="mqtt_host">
                                        {
                                            state?.edit_mode ?
                                            (
                                                <Input allowClear placeholder='MQTT Host' style={{minWidth:120,}} />
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span>{localStorage?.getItem("mqtt_host") ?? <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{"No host set"}</span>}</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                        </Form.Item>
                                    </div>
                                </div>
                            </List.Item>

                            <List.Item>
                                <div style={{display:'flex', width:'100%', alignItems:'center',}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Username</span>
                                    </div>
                                    <div style={{marginLeft:'auto',}}>
                                        <Form.Item name="mqtt_username">
                                        {
                                            state?.edit_mode ?
                                            (
                                                <Input allowClear placeholder='MQTT Username' style={{minWidth:120,}} />
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span>{localStorage?.getItem("mqtt_username") ?? <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{"No username set"}</span>}</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                        </Form.Item>
                                    </div>
                                </div>
                            </List.Item>

                            <List.Item>
                                <div style={{display:'flex', width:'100%', alignItems:'center',}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Password</span>
                                    </div>
                                    <div style={{marginLeft:'auto',}}>
                                        <Form.Item name="mqtt_password">
                                        {
                                            state?.edit_mode ?
                                            (
                                                <Input allowClear placeholder='MQTT Password' style={{minWidth:120,}} />
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span>{localStorage?.getItem("mqtt_password") ?? <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{"No password set"}</span>}</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                        </Form.Item>
                                    </div>
                                </div>
                            </List.Item>

                            <List.Item>
                                <div style={{display:'flex', width:'100%', alignItems:'center',}}>
                                    <div style={{textAlign:'start',}}>
                                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Topic</span>
                                    </div>
                                    <div style={{marginLeft:'auto',}}>
                                        <Form.Item name="mqtt_topic">
                                        {
                                            state?.edit_mode ?
                                            (
                                                <Input allowClear placeholder='MQTT Topic' style={{minWidth:120,}} />
                                            )
                                            :
                                            (
                                                <>
                                                    <div>
                                                        <span>{localStorage?.getItem("mqtt_topic") ?? <span style={{fontSize:12, color : 'var(--secondary-text-color)'}}>{"No topic set"}</span>}</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                        </Form.Item>
                                    </div>
                                </div>
                            </List.Item>
                        </List>
                    </Card>
                </div>
            </Form>
        </>
    );
}

export default MqttSettingList;