import React, { useEffect } from 'react';
import './face_recognition.css';
import useWindowSize from '../../helpers/hooks/useWindowSize';
import { useSelector } from 'react-redux';
import Recognize2Wrapper from './components/recognize2_wrapper';
import { useParams } from 'react-router-dom';

const FacialRecognitionIndex = () => {
    const { entrance_no } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const is_checkout = queryParams.get("checkout")
    return (
        <>
            <Recognize2Wrapper entrance_no={entrance_no} is_checkout={is_checkout} />
        </>
    );
}

export default FacialRecognitionIndex;