import { Button, InputNumber, List, Popconfirm, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import faceHelper from '../../../helpers/functions/face.helper';
import DefaultEntrance from '../setting_list/default_entrance';
import DefaultVoice from '../setting_list/default_voice';
import SettingDistance from '../setting_list/distance';

const SettingList = () => {
    return (
        <>
            <List>
                <List.Item key={'default_entrance'}>
                    <DefaultEntrance />
                </List.Item>

                <List.Item key={'default_voice'}>
                    <DefaultVoice />
                </List.Item>

                <List.Item key={'distance'}>
                    <SettingDistance />
                </List.Item>
            </List>
        </>
    );
}

export default SettingList;