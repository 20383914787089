import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getEntrance(query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`config/entrance?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

async function getEntranceByPark(query){
    const queryString = serviceHelper.queryString(query);
    const entranceResponse = await axios.get(`config/entrance/park?${queryString}`, { withCredentials : true });
    return entranceResponse?.data;
}

export default {
    getEntrance,
    getEntranceByPark,
}