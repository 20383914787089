import { CopyFilled } from '@ant-design/icons';
import { Avatar, Button, Modal, Result, Space, notification } from 'antd';
import moment from 'moment';
import React from 'react';
import Barcode from 'react-barcode';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SuccessTicketUserDetail = ({ ticket_user, face_image }) => {
    const image = ticket_user?.image_url;

    return (
        <>
            <Result 
            icon={
                <>
                    <div >
                        <div 
                        className="border-circle" 
                        style={{
                            border : '2px solid rgba(255, 255, 255, 0.6)', 
                            borderRadius:'50%', 
                            padding:8, 
                            maxWidth:'fit-content',
                            margin : 'auto',
                        }}
                        >
                            <Avatar size={140} src={
                                <LazyLoadImage
                                effect='blur'
                                // placeholder={"/banners/empty-user.png"}
                                style={{ width:140, height:140, objectFit:'cover' }}
                                src={ image ?? "/banners/empty-user.png"}
                                />
                            } 
                            />
                        </div>
                    </div>
                </>
            }
            title={
                <>
                    <div>
                        <div>
                            <span className='label ticket-label' style={{color : '#fff', fontWeight:'bold', fontSize:28}}>
                                {ticket_user?.name ?? ticket_user?.ticket?.name}
                            </span>
                        </div>
                        <div>
                            <Space>
                                <div>
                                    <span className='label ticket-label' style={{color : 'rgba(255, 255, 255, 0.7)', fontWeight:'bold', fontSize:20}}>
                                        {ticket_user?.ticket_user_no}
                                    </span>
                                </div>
                            </Space>
                        </div>
                    </div>
                    {/* Generate Barcode */}
                    <div style={{marginTop:24}}>
                        <Barcode font='retroGaming' fontSize={14} format='CODE128' width={1.2} height={60} value={ticket_user?.ticket_user_no} />
                    </div>
                    <div style={{marginTop:12}}>
                        <Space direction='vertical' style={{textAlign:'left', justifyContent: 'center'}}>
                            <div>
                                <Space size={2} direction='vertical'>
                                    <div>
                                        <span className='label' style={{color : 'rgba(255,255,255, 0.7)', fontWeight:'bold', fontSize:14}}>Ticket Type</span>
                                    </div>
                                    <span className='description' style={{color:'#fff', fontWeight:'bold', fontSize:14}}>
                                        {
                                            `${(ticket_user?.ticket_user_type?.name)} 
                                            ${ticket_user?.ticket_user_type?.max_age ? 
                                                `(Age: ${(ticket_user?.ticket_user_type?.min_age)}-${(ticket_user?.ticket_user_type?.max_age)})` 
                                                :
                                                `(Age: ${(ticket_user?.ticket_user_type?.min_age)}-100+)`
                                            }`
                                        }
                                    </span>
                                </Space>
                            </div>
                            <div>
                                <Space size={2} direction='vertical'>
                                    <div>
                                        <span className='label' style={{color : 'rgba(255,255,255, 0.7)', fontWeight:'bold', fontSize:14}}>Event</span>
                                    </div>
                                    <span className='description' style={{color:'#fff', fontWeight:'bold', fontSize:14}}>
                                        {`${ticket_user?.ticket?.park?.name} (${moment((ticket_user?.open_date ?? ticket_user?.ticket?.visit_date)).format("Do MMM YYYY")})`}
                                    </span>
                                </Space>
                            </div>
                        </Space>
                    </div>
                </>
            }
            />
        </>
    );
}

export default SuccessTicketUserDetail;