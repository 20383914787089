import React, { useEffect, useState } from 'react';
import EntranceList from './entrance_list';
import { Redirect } from 'react-router-dom';
import entranceService from '../../../services/config/entrance.service';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const HomeLayout = () => {
    const [state, setState] = useState({
        loading : false,
        default_entrance_link : null,
    })

    const checkDefault = async () => {
        
        const default_entrance = localStorage?.getItem('default_entrance');
        if(default_entrance){
            setState(state => ({...state, loading : true}));

            // split if there is ? found
            const split_entrance = default_entrance?.includes("?") ? default_entrance?.split("?")[0] : default_entrance;

            const entranceResponse = await entranceService?.getEntrance({ entrance_no : split_entrance });
            const { entrances } = entranceResponse;
            const entrance = entrances?.length > 0 ? entrances[0] : null;
            //redirect to link
            const link = `${entrance?.integration_name ? `/${entrance?.integration_name}` : ''}/recognize/${default_entrance}`;
            setState(state => ({...state, default_entrance_link : link, loading : false, }));
        }
    }

    useEffect(() => {
        checkDefault();
    }, [])

    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
            {
                state?.default_entrance_link ?
                (
                    <>
                        <Redirect to={state?.default_entrance_link} />
                    </>
                )
                :
                (
                    <>
                        <EntranceList />
                    </>
                )
            }
            </Spin>
        </>
    );
}

export default HomeLayout;