import React from 'react';
import { motion } from 'framer-motion';

const EntranceExit = ({children, exitY, delay, duration, style}) => {
    return (
        <>
            <motion.div
            initial={{ y: (0), opacity: 1 }}
            animate={{ y: (exitY ?? 50), opacity: 0 }}
            transition={{ duration: (duration ?? 1), delay: (delay ?? 0), type: "spring", stiffness: 260, damping: 20 }}
            style={style}
            >
                {children}
            </motion.div>
        </>
    );
}

export default EntranceExit;