import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal, Space, Spin } from 'antd';

// component
import BitLoader from '../../../helpers/components/bit_loader.component';
import TimeDisplay from './time_display';

//face detections
import { FaceDetection } from '@mediapipe/face_detection';
import * as cam from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';

// helper
import canvasHelper from '../../../helpers/functions/canvas.helper';
import faceHelper from '../../../helpers/functions/face.helper';

// service
import recognitionService from '../../../services/recognition/recognition.service';

// lottie
import Lottie from 'lottie-react';
import clickJson from '../../../helpers/lotties/click.json';
import facialJson from '../../../helpers/lotties/facial.json';

const WebcamRecognize2 = ({entrance, is_checkout, mqtt, }) => {
    const webcamRef = useRef();
    const canvasRef = useRef();
    const spinnerRef = useRef();
    const maskRef = useRef();
    const initializeRef = useRef();
    const loadRef = useRef();

    // let status = 'initialize';
    let status = 'loading';
    let face = null;
    var camera = null;

    // calculate responsive width and height
    const { innerWidth, innerHeight } = window;
    const calculated_width = (innerWidth - (innerWidth * 0.3));
    const is_full_screen = innerHeight > innerWidth;
    const responsive_width = is_full_screen ? innerWidth : calculated_width;
    const responsive_height = (innerHeight);

    function onResults(results) {
        //setting of height and width of canvas,
        canvasRef.current.width = webcamRef.current.video.videoWidth;
        canvasRef.current.height = webcamRef.current.video.videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext('2d');
        canvasCtx.save();

        // Draw the overlays.
        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height
        );

        if (results.detections.length > 0) {
            const boundingBox = results.detections[0].boundingBox;
            const size = (boundingBox?.width * boundingBox?.height);
            //size to scan -> 0.055
            const distance_setting = localStorage?.getItem("default_distance");
            const isScan = size > (distance_setting ?? 0.055); //this set to adjustable using admin dashboard in future

            // Rounded rectangle with four different radius
            const { width, height, xCenter : x, yCenter : y } = boundingBox ?? {};
            canvasHelper.drawRect(canvasCtx, boundingBox, { color: isScan ? 'green' : 'orange', lineWidth: 3}, isScan);
            
            const face_detected_count = results?.detections?.length;
            const isStartScan = isScan && face_detected_count && status == 'idle';
            if(isStartScan){
                //capture image
                canvasHelper.drawProgressIndicator(spinnerRef, true);
                canvasHelper.drawMask(maskRef, 'load');
                const captureImage = capturePicture().then((re) => {
                    const { result, pictureSrc, } = re;
                    faceHelper.modalHandler(result, entrance, pictureSrc);

                    // mqtt
                    if(result?.result){
                        mqtt?.publish({
                            ticket_user_no : result?.ticket_user?.ticket_user_no,
                            pass_code : result?.pass?.pass_code,
                        });
                    }
                    
                    canvasHelper.drawMask(maskRef, '');
                    canvasHelper.drawProgressIndicator(spinnerRef, false);
                    
                });
            }
        }
        
        canvasCtx.restore();
    }

    async function capturePicture(){
        status = 'recognize';
        const pictureSrc = webcamRef.current.getScreenshot();
        const result = await recognizePicture(pictureSrc);

        setTimeout(() => {
            status = 'waiting';
            Modal.destroyAll();

            setTimeout(() => {
                status = 'idle'; // delay 1.5 seconds before start scanning again
            }, 1500);
        }, 1500);

        return {
            result,
            pictureSrc
        };
    }

    //run recognize image
    const recognizePicture = async (picture) => {
        //using the picture to send recognize
        const binaryImage = await fetch(picture);
        const trim = await binaryImage.blob();
        const recognitionResponse = await recognitionService.recognizeImage({
            image : trim,
            entrance_no : entrance?.entrance_no,
            is_force_checkout : is_checkout ?? 0,
            park_code : entrance?.park?.park_code,
        }).catch((err) => {
            // dispatch(set_subject(null));
            // setFaceResult(faceResult => ({...faceResult, name : null, distance : null, loading  : false,}));
        });

        const { result, ticket_user, pass, account, type, detect_face, } = recognitionResponse ?? {};

        return {
            result, ticket_user, pass, account, type, detect_face
        };
    }

    // face detections
    const initializeFaceDetection = async () => {
        const faceDetection = new FaceDetection({
            locateFile: (file) => {
            //   return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`;
            return `/js/facial-recognition/${file}`;
            },
        });

        faceDetection.setOptions({
            // selfieMode: true,
            model: "short",
            minDetectionConfidence: 0.8
            // modelSelection: 0,
        });

        faceDetection.onResults((results) => {
            const isScan = onResults(results);

            if(status == 'loading'){
                // set a loader
                status = 'initializing';
                canvasHelper?.setLoaded(loadRef, initializeRef);   
            }
        });

        const videoElement = webcamRef.current.video;
        if (webcamRef.current) {
            camera = new cam.Camera(videoElement, {
            onFrame: async () => {
                await faceDetection.send({ image: videoElement });
            },
            //   width: 480,
            //   height: 640,
            });
            camera.start();
        }
    };

    const clickInitialize = () => {
        faceHelper?.speech(`Welcome`);
        canvasHelper?.setInitialized(initializeRef);

        setTimeout(() => {
            status = 'idle';
        }, 1500);
    }

    useEffect(() => {
        initializeFaceDetection();
    }, []); //this count is to reinitiaze the detection

    return (
        <>
            <div>
                <Webcam
                ref={webcamRef}
                audio={false}
                // ref={webcamRef}
                className="face-box green"
                mirrored
                screenshotQuality={1}
                screenshotFormat='image/jpeg'
                style={{
                    position: "absolute",
                    margin: "auto",
                    // textAlign: "center",
                    // top: 100,
                    left: 0,
                    right: 0,
                    // display:'none',
                    width : responsive_width,
                    height : responsive_height,
                    // display : 'none'
                }}
                videoConstraints={{
                    facingMode : 'user'
                }}
                />

                <canvas
                ref={canvasRef}
                className="cam-canvas face-box green"
                style={{width : responsive_width, height: responsive_height, }}
                />

                {/* for item stacked on camera start from here */}
                <div
                ref={maskRef}
                className="cam-canvas mask-canvas"
                style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}
                >
                    <div ref={spinnerRef} style={{display:'none'}}>
                        <div>
                            <BitLoader size={10} />
                            <div style={{textAlign:'center', marginTop:'50%'}}><span className='pixel-label' style={{color:'#fff', fontSize:16}}>Recognizing</span></div>
                        </div>
                    </div>
                </div>

                <div className='date-detail cam-canvas' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height, padding : 12,}}>
                    <div style={{ background:'rgba(0, 0, 0, 0.6)', padding : 12, borderRadius:8, cursor :'pointer', }}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{ textAlign:'start', color : '#fff'}}>
                                <div>
                                    <span className='pixel-label' style={{color:'#fff',}}>{entrance?.name}</span>
                                </div>
                                {/* <Button onClick={handleFullScreen} type='text' icon={<ExpandOutlined style={{color : '#fff'}} />}></Button> */}
                            </div>
                            <div style={{marginLeft : 'auto'}}>
                                <TimeDisplay /> 
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={loadRef} className='cam-canvas cam-loading' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={facialJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <div className='bit-loader dark' style={{width : 10, height : 10, position :'relative'}}></div>
                        </div>
                    </div>
                </div>
                
                <div ref={initializeRef} className='cam-canvas cam-activate hide' style={{transform:'scaleX(1)', width : responsive_width, height: responsive_height,}}>
                    <div onClick={clickInitialize} style={{ display: 'flex', flexDirection:'column', justifyContent : 'center', alignItems:'center', height:'100%' }}>
                        <div>
                            <Lottie animationData={clickJson} loop style={{width: 320, height : 320}} />
                        </div>
                        <div>
                            <span className='pixel-label' style={{ fontSize:24, color : 'rgba(0, 0, 0, 0.8)' }}>{'Click to initialize'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebcamRecognize2;