import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import settingFunction from '../setting.function';

const SettingDistance = () => {
    const default_value = 0.055;
    const [state, setState] = useState({
        loading : false,
        distance : null,
        edit_mode : false,
    })

    const initializeDefaultDistance = () => {
        setState(state => ({...state, loading : true, }));
        const distance = localStorage?.getItem("default_distance") ?? default_value;
        setState(state => ({...state, distance : distance, loading : false,}));
    }

    const onChange = (e) => {
        setState(state => ({...state, distance : e}));
    }

    const updateDefaultDistanceSetting = () => {
        const distance = state?.distance;
        settingFunction?.updateSettingLocal("default_distance", distance);

        setState(state => ({...state, edit_mode : false,}));
        notification?.success({
            message : 'Successful Update Settings',
            description : `Distance : ${distance ?? "No Default"}`
        })
    }

    useEffect(() => {
        initializeDefaultDistance();
    }, [])

    return (
        <>
            <div style={{display:'flex', width:'100%', alignItems:'center'}}>
                <div style={{textAlign:'start',}}>
                    <div>
                        <span style={{color:'var(--secondary-text-color)', fontWeight:500,}}>Distance</span>
                    </div>
                    <div>
                        <span style={{fontSize:12, color : 'var(--secondary-text-color)',}}>The larger the value, the distance required will be nearer.</span>
                    </div>
                </div>
                <div style={{marginLeft:'auto',}}>
                    <Spin style={{width : '100%', }} spinning={state?.loading} indicator={<LoadingOutlined />}>
                        {
                            state?.edit_mode ?
                            (
                                <>
                                    <Space>
                                        <div>
                                            <InputNumber onChange={onChange} precision={4} placeholder={default_value} value={state?.distance} />
                                        </div>
                                        <div>
                                            <Space>
                                                <Button
                                                onClick={updateDefaultDistanceSetting}
                                                size={'small'}
                                                icon={<CheckOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                                <Button
                                                onClick={() => setState(state => ({...state, edit_mode : false,}))}
                                                size={'small'}
                                                icon={<CloseOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                            </Space>
                                        </div>
                                    </Space>
                                </>
                            )
                            :
                            (
                                <>
                                    <Space>
                                        <div>
                                        <span>{(state?.distance)}</span>
                                            
                                        </div>
                                        <div>
                                            <Space>
                                                <div>
                                                    <Button
                                                    onClick={() => setState(state => ({...state, edit_mode : true,}))}
                                                    size={'small'}
                                                    icon={<EditOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                    />
                                                </div>
                                            </Space>
                                        </div>
                                    </Space>
                                </>
                            )
                        }
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default SettingDistance;