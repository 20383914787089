import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getApp(query){
    const queryString = serviceHelper.queryString(query);
    const appResponse = await axios.get(`config/other/app?${queryString}`, { withCredentials : true });
    return appResponse?.data;
}

export default {
    getApp,
}