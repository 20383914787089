import React, { useEffect } from 'react';
import KidzaniaRecognize2Wrapper from './components/kidzania_recognize2_wrapper';
import { useParams } from 'react-router-dom';
import './scanner.css';
import { useDispatch } from 'react-redux';
import { set_activity, set_payment_type } from '../../actions/integration/kidzania.action';

const KidzaniaFaceRecognition = () => {
    const dispatch = useDispatch();
    const { entrance_no } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const is_checkout = queryParams.get("checkout")

    useEffect(() => {
        return () => {
            localStorage?.removeItem("kidzania_activity");
            localStorage?.removeItem("kidzania_payment_type");
            dispatch(set_activity(null));
            dispatch(set_payment_type(null));
        }
    }, [])
    return (
        <>
            <KidzaniaRecognize2Wrapper entrance_no={entrance_no} is_checkout={is_checkout} />
        </>
    );
}

export default KidzaniaFaceRecognition;