import { Result } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import checkLottieJson from '../../../helpers/lotties/check-no.json';
import Lottie from "lottie-react";

const FailScan = () => {
    return (
        <>
            <Result 
            icon={
                <Lottie animationData={checkLottieJson} loop={false} style={{ height : 160 }}/>
            }
            title={
                <>
                    <div>
                        <div>
                            <span className='label' style={{color : '#fff', fontWeight:'bold', fontSize:24}}>
                                No face found
                            </span>
                        </div>

                        <div style={{marginTop:32,}}>
                            <span className='description' style={{color:'rgba(255, 255, 255, 0.8)', fontWeight:'bold', fontSize:16}}>
                                Face scan unsuccessful. Please retry or contact customer service for assistance
                            </span>
                        </div>
                    </div>
                </>
            }
            />
        </>
    );
}

export default FailScan;