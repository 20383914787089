import React, { useEffect, useState } from 'react';
import kidzoftService from '../../../services/integration/kidzoft.service';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Popover, Row, Space } from 'antd';
import DynamicSvg from '../../../helpers/components/dynamic_svg';
import { set_activity } from '../../../actions/integration/kidzania.action';
import { LoadingOutlined } from '@ant-design/icons';

const KidzaniaActivity = ({ entrance, }) => {
    const dispatch = useDispatch();
    const kidzaniaRedux = useSelector(state => state?.kidzania)
    const [state, setState] = useState({
        loading : false,
        popover_modal : false,
        establishment_activities : [],
    })

    const fetchEstablishmentActivity = async () => {
        setState(state => ({...state, loading : true, }));
        const establishmentId = (entrance?.establishment?.integration_establishment_id)
        const { park_id } = entrance?.park;
        const establishmentActivityResponse = await kidzoftService?.getEstablishmentActivity(establishmentId, park_id); // establishment id
        setState(state => ({...state, loading : false, establishment_activities : establishmentActivityResponse?.establishment_activities, }))
    }

    const onSelectEstablishmentActivity = async (activity) => {
        dispatch(set_activity(activity));
        localStorage.setItem("kidzania_activity", JSON.stringify(activity))
        setState(state => ({...state, popover_modal : false,}));
    }

    const defaultSetEstablishmentActivity = () => {
        const default_activity = localStorage?.getItem("kidzania_activity");
        if(default_activity){
            const establishment_activity = (JSON.parse(default_activity))
            dispatch(set_activity(establishment_activity));
        }
    }

    useEffect(() => {
        fetchEstablishmentActivity().then(() => {
            defaultSetEstablishmentActivity();
        });
    }, [])

    return (
        <>
            <div style={{padding : 12, paddingBottom:0, minWidth : 100,}}>
                <Popover
                placement='bottomRight'
                trigger={'click'}
                overlayStyle={{ padding: 12,}}
                showArrow={false}
                open={state?.popover_modal}
                content={
                    <>
                        {
                            state?.loading ?
                            (
                                <>
                                    <div style={{padding : 12, }}>
                                        <LoadingOutlined style={{color:'var(--main-color)'}} />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div style={{ width : 500, }}>
                                        <Row gutter={[18, 18]}>
                                        {
                                            state?.establishment_activities?.map((activity, index) => {
                                                const selected = (kidzaniaRedux?.activity?.activityId) === activity?.activityId;
                                                return (
                                                    <Col key={index} xs={24} md={12}>
                                                        <Card
                                                        className={`activity-card ${selected ? 'selected' : ''}`}
                                                        style={{cursor:'pointer',}}
                                                        onClick={() => onSelectEstablishmentActivity(activity)}
                                                        bodyStyle={{padding : 12,}}
                                                        >
                                                            <Space size={12} style={{width : '100%',}}>
                                                                <div style={{ display:'flex', height: 30, width: 30, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                                    <div style={{margin:'auto'}}>
                                                                        <DynamicSvg image={'/menus/door.svg'} color={'#6f7287'} size={12} />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div>
                                                                        <span>{activity?.activityDescription}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>{activity?.areaDescription}</span>
                                                                    </div>
                                                                </div>
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                        </Row>
                                    </div>
                                </>
                            )
                        }
                    </>
                }
                >
                    <div
                    onClick={() => setState(state => ({...state, popover_modal : !state?.popover_modal, }))}
                    style={{ background:'rgba(0, 0, 0, 0.6)', padding : 12, border : '2px solid var(--main-color)', borderRadius:8, cursor :'pointer' }}
                    >
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'100%', textAlign:'start', color : '#fff'}}>
                                {
                                    kidzaniaRedux?.activity ?
                                    (
                                        <>
                                            <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                                <span className='pixel-label'>{kidzaniaRedux?.activity?.activityDescription}</span>
                                            </div>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <span className='pixel-label'>Select Activity</span>
                                        </>
                                    )
                                }
                            </div>
                            <div style={{marginLeft:'auto', paddingRight:12,}}>
                                <DynamicSvg image={'/menus/pixel-caret-down.svg'} color={'#fff'} size={24} />
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>
        </>
    );
}

export default KidzaniaActivity;