import React from 'react';
import './bit_loader.css';

const BitLoader = ({size}) => {
    return (
        <>
            <div className='bit-loader' style={{width : size, height : size}}></div>
        </>
    );
}

export default BitLoader;