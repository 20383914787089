import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './private.router';

// components
import HomeIndex from '../containers/home';
import SettingIndex from '../containers/settings';

// recognition
import FacialRecognitionIndex from '../containers/face_recognition';
import FacialRecognitionTicket from '../containers/face_recognition/ticket';

// Kidzania
import KidzaniaFaceRecognition from '../containers/kidzania_face_recognition';

const AppRouter = () => {
    return (
        <>
            <Switch>
                {/* Home */}
                <PrivateRoute component={HomeIndex} path="/" exact restricted={false} />


                {/* Face Recognition */}
                <PrivateRoute component={FacialRecognitionIndex} path="/recognize/:entrance_no" exact restricted={false} />
                <PrivateRoute component={FacialRecognitionTicket} path="/ticket/recognize/:park_code" exact restricted={false} />

                {/* Designated for Kidzania */}
                <PrivateRoute component={KidzaniaFaceRecognition} path="/kidzania/recognize/:entrance_no" exact restricted={false} />

                {/* Settings */}
                <PrivateRoute component={SettingIndex} path="/settings" exact restricted={false} />
            </Switch>
        </>
    );
}

export default AppRouter;