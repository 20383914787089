export const set_activity = (establishment_activity,) => {
    return {
        type : 'SET_ACTIVITY',
        establishment_activity,
    };
}

export const set_payment_type = (payment_type) => { // 1 for cash, 2 for card
    return {
        type : 'SET_PAYMENT_TYPE',
        payment_type,
    }
}