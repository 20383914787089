import { Alert, Avatar, Image, Result, } from 'antd';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const EstablishmentSuccessScan = ({ pass, ticket_user, type, entrance, integration_response, }) => {
    let image;
    switch(type){
        case 'ticket' : 
            image = ticket_user?.image_url;
            break;
        case 'pass' :
            image = pass?.url_image;
            break;
        default :
            break;
    }

    // convert data
    const establishment_integration = integration_response && JSON.parse(integration_response);
    const { data, response, } = establishment_integration ?? {};
    const is_show_amount = data?.activityPaymentType === 'Charge';
    return (
        <>
            <Result 
            icon={
                <>
                    <div >
                        <div 
                        className="border-circle" 
                        style={{
                            border : '2px solid rgba(255, 255, 255, 0.6)', 
                            borderRadius:'50%', 
                            padding:8, 
                            maxWidth:'fit-content',
                            margin : 'auto',
                        }}
                        >
                            <Avatar size={140} src={
                                <LazyLoadImage
                                effect='blur'
                                // placeholder={"/banners/empty-user.png"}
                                style={{ width:140, height:140, objectFit:'cover' }}
                                src={ image ?? "/banners/empty-user.png"}
                                />
                            } 
                            />
                        </div>
                    </div>
                </>
            }
            title={
                <>
                    <div>
                        <div>
                            <span className='label' style={{color : '#fff', fontWeight:'bold', fontSize:24}}>{entrance?.display_message}</span>
                        </div>

                        <div style={{marginTop:32,}}>
                            {
                                response?.success && is_show_amount ?
                                (
                                    <>
                                        <EntranceMotion initialY={120} delay={0.3}>
                                            <div>
                                                <LazyLoadImage
                                                effect='blur'
                                                // placeholder={"/banners/empty-user.png"}
                                                style={{ width:120, height:120, objectFit:'cover' }}
                                                src={'/banners/kidzos_coin.png' }
                                                />
                                            </div>
                                            <div>
                                                <span className='pixel-label' style={{color : '#fff',}}>{data?.activityAmount}</span>
                                            </div>
                                        </EntranceMotion>
                                    </>
                                )
                                :
                                (
                                    <>
                                        {/* Hide it first before Safriyal want it live */}
                                        {/* <div>
                                            <Alert
                                            type='error'
                                            message={"Integration Fail"}
                                            description={JSON.stringify(response?.errors)}
                                            />
                                        </div> */}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </>
            }
            />
        </>
    );
}

export default EstablishmentSuccessScan;