import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getBankAccount(card_number, park_id){
    const bankAccountResponse = await axios.get(`integration/kidzoft/park/${park_id}/bankAccount/${card_number}`, { withCredentials : true });
    return bankAccountResponse?.data;
}

async function getEstablishmentActivity(establishmentId, park_id){
    const establishmentActivityResponse = await axios.get(`integration/kidzoft/park/${park_id}/establishmentActivity/${establishmentId}`, { withCredentials : true, });
    return establishmentActivityResponse?.data;
}

async function parkEntrance(params){
    const parkEntranceResponse = await axios.post(`integration/kidzoft/parkEntrance`, params, { withCredentials : true, });
    return parkEntranceResponse?.data;
}

async function establishmentAccess(params){
    const establishmentAccessResponse = await axios.post(`integration/kidzoft/establishmentAccess`, params, { withCredentials : true, });
    return establishmentAccessResponse?.data;
}

export default {
    getBankAccount,
    getEstablishmentActivity,
    parkEntrance,
    establishmentAccess,
}