import { Button, Card, Form, Input } from 'antd';
import React, { useState } from 'react';
import authService from '../../../services/admin/auth.service';
import ContainerComponent from '../../../helpers/components/container.component';
import { useHistory } from 'react-router-dom';

const LoginLayout = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [state, setState] = useState({
        login_loading : false,
    })
    const onLogin = async () => {
        setState(state => ({...state, login_loading : true,}));
        const { username, password } = form.getFieldsValue();
        const loginResponse = await authService.login({
            username : username,
            password : password,
        }).catch(err => {
            setState(state => ({...state, login_loading : false,}));
        });

        setState(state => ({...state, login_loading : false,}));
        if(loginResponse?.token){
            //success login
            
            window.location.reload(false);
        }
    }

    return (
        <>
            <div className='content' style={{display:'flex', justifyContent:'center', flexDirection : 'column'}}>
                <div>
                    <div>
                        <div>
                            <span
                            className='ticket-label ticket-title h1'
                            style={{
                                fontWeight:'bold',
                                fontSize: 24
        
                            }}
                            >
                                Enter your credentials
                            </span>
                        </div>
                    </div>

                    <div style={{marginTop:42,}}>
                        <Form
                        form={form}
                        onFinish={onLogin}
                        >
                            <Form.Item name={'username'}>
                                <Input size='large' placeholder='Username'/>
                            </Form.Item>

                            <Form.Item name={'password'}>
                                <Input size='large' placeholder='Password' type='password'/>
                            </Form.Item>

                            <Form.Item>
                                <Button loading={state?.login_loading} htmlType='submit' type='primary' size='large' style={{width:'100%'}}>
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginLayout;