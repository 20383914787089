import moment from "moment";
import mqtt from "mqtt";
// ws://broker.emqx.io:8083/mqtt
class MqttClass {
    mqtt_topic;
    client;
    status;
    connect_error;
    constructor(mqtt_host, mqtt_username, mqtt_password, mqtt_topic){
        try{
            if(mqtt_host){ // only bind with mqtt if mqtt host is not empty
                const mqtt_client = mqtt.connect(mqtt_host, {...mqtt_username && ({ username : mqtt_username }), ...mqtt_password && ({ password : mqtt_password, }),},);
                // const mqtt_client = mqtt.connect('ws://192.168.100.123:8083/mqtt', { username : 'raven', password : 'm7k@Cqu$3w', });
                this.mqtt_topic = mqtt_topic;
                this.client = mqtt_client;
            }
        }catch(err){
            this.client = null;
            this.connect_error = err;
        }
    }

    publish({ ticket_user_no, pass_code, }){        
        if(this.client){
            const content = {
                ticket_user_no,
                pass_code,
                datetime : moment()?.format("YYYY-MM-DD HH:mm:ss"),
                text : "OPEN_TURNSTILE",
            }
            this.client.publish(this.mqtt_topic, JSON.stringify(content), (error) => {
                if(error){
                    console.log("publish error: ", error);
                }
            })
        }
    }
}

export default MqttClass;