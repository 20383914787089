import React, { useEffect, useState } from 'react';
import entranceService from '../../../services/config/entrance.service';
import { Card, Col, Empty, Input, Row, Space, Spin } from 'antd';
import EntranceCard from './entrance_card';
import { LoadingOutlined,} from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const EntranceList = () => {
    const [state, setState] = useState({
        loading : false,
        parks : [],
    })
    const [timer, setTimer] = useState(null);

    const [filter, setFilter] = useState({
        search : null,
        entrance_type : null,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
        }, 300);
        setTimer(newTimer);
    }

    const fetchEntrances = async () => {
        setState(state => ({...state, loading : true}));
        const { search } = filter;
        const entranceResponse = await entranceService.getEntranceByPark({
            search,
        });
        
        setState(state => ({...state, loading : false, parks : entranceResponse?.parks}));
    }

    useEffect(() => {
        fetchEntrances();
    }, [filter]);

    return (
        <>
            <div>
                <div className='filter-panel'>
                    <div>
                        <Input onChange={(e) => doneTyping(e?.target?.value)} placeholder='Search Entrances' />
                    </div>
                    <div>
                        <Space>
                            
                        </Space>
                    </div>
                </div>
                <div>
                    <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                        <div style={{textAlign:'start', marginTop:24,}}>
                            <Space size={40} direction='vertical' style={{width:'100%'}}>
                            {
                                state?.parks?.map((park, index) => {
                                    const { name : park_name, entrances } = park ?? {};
                                    return (
                                        <div key={index}>
                                            <div>
                                                <span style={{fontWeight:500, color:'var(--secondary-text-color)'}}>{park_name}</span>
                                            </div>

                                            <div style={{marginTop:12,}}>
                                                {
                                                    entrances?.length > 0 ?
                                                    (
                                                        <>
                                                            <Row gutter={[20, 20]}>
                                                            {
                                                                entrances?.map((entrance, index) => {
                                                                    return (
                                                                        <Col xs={24} sm={12} lg={6} md={8} span={6} key={index}>
                                                                            <div style={{height:'100%',}}>
                                                                                <EntranceMotion delay={(index * 0.05)} style={{height:'inherit',}}>
                                                                                    <EntranceCard entrance={entrance} />
                                                                                </EntranceMotion>
                                                                            </div>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                            </Row>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Entrance Found" />
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </Space>
                        </div>
                    </Spin>
                </div>
            </div>
        </>
    );
}

export default EntranceList;