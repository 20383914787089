import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Popover, Row, Space } from 'antd';
import React, { useState, useEffect, } from 'react';
import DynamicSvg from '../../../helpers/components/dynamic_svg';
import { useDispatch, useSelector } from 'react-redux';
import { set_payment_type } from '../../../actions/integration/kidzania.action';

const KidzaniaPaymentType = () => {
    const dispatch = useDispatch();
    const kidzaniaRedux = useSelector(state => state.kidzania);
    // declare payment type
    const payment_types = [{
        id : 1,
        name : 'Cash',
        image : '/menus/cash.svg',
        is_card_number : false,
    },{
        id : 2,
        name : 'Card',
        image : '/menus/card.svg',
        is_card_number : true,
    }]

    const [state, setState] = useState({
        popover_modal : false,
    });

    const onSelectPaymentType = async (payment_type) => {
        dispatch(set_payment_type(payment_type));
        localStorage.setItem("kidzania_payment_type", JSON.stringify(payment_type))
        setState(state => ({...state, popover_modal : false,}));
    }

    const defaultSetPaymentType = () => {
        const default_payment_type = localStorage?.getItem("kidzania_payment_type");
        if(default_payment_type){
            const establishment_payment_type = (JSON.parse(default_payment_type))
            dispatch(set_payment_type(establishment_payment_type));
        }
    }

    useEffect(() => {
        defaultSetPaymentType();
    }, [])

    return (
        <>
            <div style={{padding : 12, paddingBottom:0, minWidth : 100,}}>
                <Popover
                placement='bottomRight'
                trigger={'click'}
                overlayStyle={{ padding: 12,}}
                showArrow={false}
                open={state?.popover_modal}
                content={
                    <>
                        {
                            state?.loading ?
                            (
                                <>
                                    <div style={{padding : 12, }}>
                                        <LoadingOutlined style={{color:'var(--main-color)'}} />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    <div style={{ width : 500, }}>
                                        <Row gutter={[18, 18]}>
                                        {
                                            payment_types?.map((payment_type, index) => {
                                                const selected = (kidzaniaRedux?.payment_type?.id) === payment_type?.id;
                                                return (
                                                    <Col key={index} xs={24} md={12}>
                                                        <Card
                                                        className={`activity-card ${selected ? 'selected' : ''}`}
                                                        style={{cursor:'pointer', minWidth:120,}}
                                                        onClick={() => onSelectPaymentType(payment_type)}
                                                        bodyStyle={{padding : 12,}}
                                                        >
                                                            <Space size={12} style={{ width : '100%', }}>
                                                                <div style={{ display:'flex', height: 30, width: 30, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                                                                    <div style={{margin:'auto'}}>
                                                                        <DynamicSvg image={payment_type?.image} color={'#6f7287'} size={12} />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div>
                                                                        <span>{payment_type?.name}</span>
                                                                    </div>
                                                                </div>
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                        </Row>
                                    </div>
                                </>
                            )
                        }
                    </>
                }
                >
                    <div
                    onClick={() => setState(state => ({...state, popover_modal : !state?.popover_modal, }))}
                    style={{ background:'rgba(0, 0, 0, 0.6)', padding : 12, border : '2px solid var(--main-color)', borderRadius:8, cursor :'pointer' }}
                    >
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div>
                                <DynamicSvg image={kidzaniaRedux?.payment_type?.image} color={'#fff'} size={24} />
                            </div>
                            <div style={{width:'100%', textAlign:'start', color : '#fff', marginLeft: 8,}}>
                                {
                                    kidzaniaRedux?.payment_type ?
                                    (
                                        <>
                                            <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                                <span className='pixel-label'>{kidzaniaRedux?.payment_type?.name}</span>
                                            </div>
                                        </>
                                    ) :
                                    (
                                        <>
                                            <span className='pixel-label'>Select Payment Type</span>
                                        </>
                                    )
                                }
                            </div>
                            <div style={{marginLeft:'auto', paddingRight:12,}}>
                                <DynamicSvg image={'/menus/pixel-caret-down.svg'} color={'#fff'} size={24} />
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>
        </>
    );
}

export default KidzaniaPaymentType;