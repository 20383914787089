import { Modal } from "antd";
import faceHelper from "../../../helpers/functions/face.helper";
import EstablishmentSuccessScan from "../components/establishment_success_scan";
import EstablishmentExitScan from "../components/establishment_exit_scan";

async function modalHandler(result, entrance, face_image, integration_response){
    let speech_text = "";
    if(result?.result){
        const is_checkout = result?.ticket_user?.is_checkout;
        if(is_checkout){
            speech_text = entrance?.exit_message;
            exitEntranceModal(result, entrance, integration_response);
        }else{
            //speech sound
            speech_text = entrance?.display_message;
            successEntranceModal(result, entrance, face_image, integration_response);
        }
    }else{
        speech_text = "Sorry you are not recognised.";
        faceHelper?.failEntranceModal();
    }
    const default_voice = localStorage?.getItem("default_voice");
    const voice = speechSynthesis?.getVoices()[default_voice];
    faceHelper.speech(speech_text, voice);
}

async function successEntranceModal(result, entrance, face_image, integration_response){ 
    // navigator.clipboard.writeText(result?.ticket_user?.ticket_user_no);
    const color = (result?.ticket_user?.ticket_user_type?.color);
    const { type, face_image : result_image, ticket_user, pass, } = result ?? {};
    Modal.success({
        icon : null,
        // transitionName : 'none',
        maskTransitionName : 'none',
        maskStyle : {
            ...faceHelper?.maskStyle, 
            // background : '#38c172'
            background : color ?? "#38c172",
        },
        className : 'scan-success-modal',
        content : (
            <>
                <EstablishmentSuccessScan type={type} pass={pass} face_image={result_image} integration_response={integration_response} ticket_user={ticket_user} entrance={entrance} />
            </>
        ),
        footer : null,
    });
}

async function exitEntranceModal(result, entrance, integration_response){
    Modal.success({
        icon : null,
        // transitionName : 'none',
        maskTransitionName : 'none',
        maskStyle : {
            ...faceHelper?.maskStyle, 
            background : '#3490dc'
        },
        className : 'scan-success-modal',
        content : (
            <>
                <EstablishmentExitScan entrance={entrance} result={result} integration_response={integration_response} />
            </>
        ),
        footer : null,
    });
}

export default {
    modalHandler,
}