import { Col, Row } from 'antd';
import React from 'react';

const ContainerComponent = ({children, span, top, bottom}) => {
    return (
        <>
            <Row justify='center' align='center' style={{width:'100%', marginTop:(top ?? 'auto'), marginBottom : (bottom ?? 'auto')}}>
                <Col xs={22} xl={span ?? 24} span={24}>
                    <div style={{margin:'0 auto'}}>
                        {children}
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ContainerComponent;