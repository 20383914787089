const updateSettingLocal = (key, value) => {
    //set to localstorage
    localStorage.setItem(key, value);
    // localStorage.setItem("default_voice", value);
}

const deleteLocalStorageSetting = (key) => {
    localStorage.removeItem(key)
}


export default {
    updateSettingLocal,
    deleteLocalStorageSetting,
}