const initialState = {
    auth : null,
}

const authReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_AUTH':
            return {...state, auth : action.auth}
        default :
            return state;
    }
}

export default authReducer;